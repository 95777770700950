import camelCase from "lodash/camelCase"
import isArray from "lodash/isArray"
import isEqual from "lodash/isEqual"
import isObject from "lodash/isObject"
import snakeCase from "lodash/snakeCase"

type ConfigurationOptions = {
  applyOnlyToKeys?: boolean
  applyOnlyOnFirstLevel?: boolean
}

export const camelize = <T>(
  val: T,
  options: ConfigurationOptions = { applyOnlyToKeys: false, applyOnlyOnFirstLevel: false }
): T => {
  if (isArray(val)) {
    return val.map((v) => camelize(v, options)) as T
  }
  if (isObject(val)) {
    return Object.fromEntries(
      Object.entries(val).map(([key, value]) => [
        camelCase(key),
        options.applyOnlyOnFirstLevel ? value : camelize(value, options)
      ])
    ) as T
  }

  if (typeof val === "string") {
    return options.applyOnlyToKeys ? val : (camelCase(val) as T)
  }

  return val
}

export const snakize = <T>(
  val: T,
  options: ConfigurationOptions = { applyOnlyToKeys: false }
): T => {
  if (isArray(val)) {
    return val.map((v) => snakize(v, options)) as T
  }
  if (isObject(val)) {
    return Object.fromEntries(
      Object.entries(val).map(([key, value]) => [snakeCase(key), snakize(value, options)])
    ) as T
  }

  if (typeof val === "string") {
    return options.applyOnlyToKeys ? val : (snakeCase(val) as T)
  }

  return val
}

export const getDifferences = (a, b) =>
  Object.fromEntries(Object.entries(a).filter(([key, val]) => !(key in b) || !isEqual(b[key], val)))

// TODO: Move these proxy functions in a better place (once utils/ will be cleaner)
export const convertToBackend = <T>(val: T): T => snakize(val, { applyOnlyToKeys: true })

export const convertFromBackend = <T>(val: T): T => camelize(val, { applyOnlyToKeys: true })

/**
 *
 * @param originalObject object we get the subset from
 * @param keys keys of the targeted subset
 * @returns
 */
export const getSubset = <T extends {}, K extends keyof T>(originalObject: T, ...keys: K[]) => {
  return keys.reduce(
    (acc, key) => {
      if (Object.prototype.hasOwnProperty.call(originalObject, key)) {
        acc[key] = originalObject[key]
      }

      return acc
    },
    {} as Pick<T, K>
  )
}
