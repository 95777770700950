<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 20C7.1 20 8 19.1 8 18V11C8 9.9 7.1 9 6 9C4.9 9 4 9.9 4 11V18C4 19.1 4.9 20 6 20Z"
      fill="currentColor" />
    <path
      d="M16 15V18C16 19.1 16.9 20 18 20C19.1 20 20 19.1 20 18V15C20 13.9 19.1 13 18 13C16.9 13 16 13.9 16 15Z"
      fill="currentColor" />
    <path
      d="M12 20C13.1 20 14 19.1 14 18V6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6V18C10 19.1 10.9 20 12 20Z"
      fill="currentColor" />
    <path
      d="M17 14.5C17.8767 14.5 18.5 13.7278 18.5 12.8889C18.5 12.05 17.8767 11.2778 17 11.2778C16.1233 11.2778 15.5 12.05 15.5 12.8889C15.5 13.7278 16.1233 14.5 17 14.5ZM17 3.5C16.1256 3.5 15.5 4.27471 15.5 5.11111V9.55556C15.5 10.392 16.1256 11.1667 17 11.1667C17.8744 11.1667 18.5 10.392 18.5 9.55556V5.11111C18.5 4.27471 17.8744 3.5 17 3.5Z"
      fill="currentColor"
      stroke="white" />
  </svg>
</template>
