import {
  init,
  captureConsoleIntegration,
  browserTracingIntegration,
  vueIntegration
} from "@sentry/vue"
import { AxiosError } from "axios"

import { appConfig } from "./config"

import type { Router } from "vue-router"

// Couldn't get `Parameters<typeof init>` to work
type InitSentryParams = Pick<
  Exclude<Parameters<typeof init>[0], undefined>,
  "app" | "dsn" | "environment" | "release" | "tracesSampleRate" | "profilesSampleRate"
> & {
  router?: Router
}

export const getSentryParams = (): Omit<InitSentryParams, "app"> => {
  return {
    release: appConfig.appRelease ?? "",
    dsn: globalThis.sentry_dsn_js,
    environment: globalThis.environment,
    tracesSampleRate: globalThis.sentry_tracing_sampling || 0,
    profilesSampleRate: globalThis.sentry_profiling_sampling || 0
  }
}

export const initSentry = ({ router, ...sentryParams }: InitSentryParams): void => {
  if (!sentryParams.release) {
    return
  }

  init({
    ...sentryParams,
    integrations: [
      vueIntegration({
        tracingOptions: {
          trackComponents: true
        }
      }),
      captureConsoleIntegration({ levels: ["warn", "error"] }),
      browserTracingIntegration({ router })
    ],

    beforeSend(event, hint) {
      const error = hint.originalException

      if (error instanceof AxiosError) {
        return null
      }

      const sentriesToIgnore = "ResizeObserver"

      if (event.message?.match(sentriesToIgnore)) {
        return null
      }

      return event
    }
  })
}
