<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 7.24245C15.58 7.24245 15.17 7.28245 14.76 7.35245L1.9 1.84245C1.24 1.55245 0.51 2.04245 0.51 2.75245L0.5 7.44245C0.5 7.91245 0.83 8.32245 1.28 8.42245L9.5 10.2424L1.28 12.0724C0.83 12.1724 0.5 12.5724 0.5 13.0424L0.51 17.7224C0.51 18.4424 1.24 18.9224 1.9 18.6424L8.58 15.7824C9.09 19.4324 12.21 22.2424 16 22.2424C20.14 22.2424 23.5 18.8824 23.5 14.7424C23.5 10.6024 20.14 7.24245 16 7.24245ZM16 20.2424C12.97 20.2424 10.5 17.7724 10.5 14.7424C10.5 11.7124 12.97 9.24245 16 9.24245C19.03 9.24245 21.5 11.7124 21.5 14.7424C21.5 17.7724 19.03 20.2424 16 20.2424Z"
      fill="currentColor" />
    <path
      d="M18.12 12.6224C17.92 12.4224 17.61 12.4224 17.41 12.6224L16 14.0324L14.59 12.6224C14.39 12.4224 14.08 12.4224 13.88 12.6224C13.68 12.8224 13.68 13.1324 13.88 13.3324L15.29 14.7424L13.88 16.1524C13.68 16.3524 13.68 16.6625 13.88 16.8625C14.08 17.0625 14.39 17.0625 14.59 16.8625L16 15.4524L17.41 16.8625C17.61 17.0625 17.92 17.0625 18.12 16.8625C18.32 16.6625 18.32 16.3524 18.12 16.1524L16.71 14.7424L18.12 13.3324C18.32 13.1324 18.32 12.8124 18.12 12.6224Z"
      fill="currentColor" />
  </svg>
</template>
