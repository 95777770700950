import { createIntl, createIntlCache } from "@formatjs/intl"
import { captureException } from "@sentry/vue"

// eslint-disable-next-line no-restricted-imports
import { intlSingleton } from "../composables/use-translate"
import { getIncludedJsonData } from "../core/init"

const jsonData = getIncludedJsonData()

const loadCatalog = async (locale: string): Promise<Record<string, string>> => {
  const response = await import(`@/locales/${locale}.json`)
  return response.default
}

export const initIntl = async () => {
  const { message, locale } = await setIntlParams()
  const intl = createIntl(
    {
      locale: locale,
      defaultLocale: "en",
      messages: message,
      onError: (error) => {
        if (error.code === "MISSING_TRANSLATION") {
          return
        }
        console.error(error)
      }
    },
    createIntlCache()
  )

  intlSingleton.$intl = intl
}

const setIntlParams = async () => {
  const DEFAULT_LOCALE = "en"
  const message: Record<string, string> = {}
  /* user locale Dependency */
  const locale = jsonData.locale || DEFAULT_LOCALE

  const defaultCatalog = await loadCatalog(DEFAULT_LOCALE)

  Object.assign(message, defaultCatalog)

  if (locale !== DEFAULT_LOCALE) {
    const [rootLang, specificLang] = locale.split("-")

    try {
      Object.assign(message, await loadCatalog(rootLang))
    } catch (e) {
      captureException(e, (scope) => {
        scope.setTag("domain", "translation")
        return scope
      })
    }

    if (specificLang) {
      try {
        Object.assign(message, await loadCatalog(locale))
      } catch (e) {
        captureException(e, (scope) => {
          scope.setTag("domain", "translation")
          return scope
        })
      }
    }
  }
  return {
    message,
    locale
  }
}
