<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g :clip-path="`url(#${clipPathId})`">
      <path
        d="M3.49512 22.0225C4.59512 22.0225 5.49512 21.1225 5.49512 20.0225V13.0225C5.49512 11.9225 4.59512 11.0225 3.49512 11.0225C2.39512 11.0225 1.49512 11.9225 1.49512 13.0225V20.0225C1.49512 21.1225 2.39512 22.0225 3.49512 22.0225ZM11.8651 16.4525L13.3351 16.9625C13.4451 17.0025 13.5551 17.0225 13.6651 17.0225H20.4951C21.6051 17.0225 22.4951 17.9125 22.5051 19.0225L15.1151 21.7925C14.7151 21.9425 14.2751 21.9625 13.8651 21.8425L7.49512 20.0425V11.0225H9.09512C9.33512 11.0225 9.57512 11.0625 9.79512 11.1525L16.7251 13.7425C17.1851 13.9125 17.4951 14.3525 17.4951 14.8525C17.4951 15.5025 16.9651 16.0325 16.3151 16.0325H13.6851C13.5651 16.0325 13.4451 16.0125 13.3251 15.9625L12.2051 15.5325C11.9451 15.4325 11.6551 15.5725 11.5651 15.8325C11.4751 16.0825 11.6051 16.3625 11.8651 16.4525ZM20.6551 5.92248L15.6551 2.35248C14.9551 1.85248 14.0251 1.85248 13.3351 2.35248L8.33512 5.92248C7.80512 6.30248 7.49512 6.90248 7.49512 7.55248V9.02248H9.10512C9.35512 9.02248 9.61512 9.07248 9.84512 9.16248L17.6351 12.2725C18.7551 12.7325 19.4951 13.8125 19.4951 15.0225H21.4951V7.55248C21.4951 6.90248 21.1851 6.30248 20.6551 5.92248ZM13.4951 10.0225C13.2151 10.0225 12.9951 9.80248 12.9951 9.52248C12.9951 9.24248 13.2151 9.02248 13.4951 9.02248C13.7751 9.02248 13.9951 9.24248 13.9951 9.52248C13.9951 9.80248 13.7751 10.0225 13.4951 10.0225ZM13.4951 8.02248C13.2151 8.02248 12.9951 7.80248 12.9951 7.52248C12.9951 7.24248 13.2151 7.02248 13.4951 7.02248C13.7751 7.02248 13.9951 7.24248 13.9951 7.52248C13.9951 7.80248 13.7751 8.02248 13.4951 8.02248ZM15.4951 10.0225C15.2151 10.0225 14.9951 9.80248 14.9951 9.52248C14.9951 9.24248 15.2151 9.02248 15.4951 9.02248C15.7751 9.02248 15.9951 9.24248 15.9951 9.52248C15.9951 9.80248 15.7751 10.0225 15.4951 10.0225ZM15.4951 8.02248C15.2151 8.02248 14.9951 7.80248 14.9951 7.52248C14.9951 7.24248 15.2151 7.02248 15.4951 7.02248C15.7751 7.02248 15.9951 7.24248 15.9951 7.52248C15.9951 7.80248 15.7751 8.02248 15.4951 8.02248Z"
        fill="currentColor" />
    </g>
    <defs>
      <clipPath :id="clipPathId">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
import { getUniqueIdentifier } from "@/src/utils/id-utils"

const clipPathId = getUniqueIdentifier()
</script>
