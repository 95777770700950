import Plausible from "plausible-tracker"

import { appConfig } from "@/src/core/config"

import { useProjectConfigStore } from "../domains/project/config/config.store"

import { $user } from "./vue_base_config"

import type { App } from "vue"
import type { RouteLocationNormalizedLoaded } from "vue-router"

const getTargetDomain = (): string | undefined => {
  const envLevel: string | undefined = globalThis.user_config?.global?.env_level

  switch (envLevel) {
    case "prod":
      return "deepki-ready.deepki.com"
    case "uat":
      return "deepki-ready-uat.deepki.com"
    case "testing":
      return "deepki-ready-testing.deepki.com"
    default:
      return undefined
  }
}

/**
 * Wrapper for Plausible analytics.
 *
 * When built for `production` environment, Plausible library will be used.
 * For `development` environment, a stub will be used.
 */
const initPlausible = ():
  | ReturnType<typeof Plausible>
  | Pick<ReturnType<typeof Plausible>, "trackEvent"> => {
  if (appConfig.appEnv !== "production") {
    return {
      trackEvent: (...args: Parameters<ReturnType<typeof Plausible>["trackEvent"]>): void => {
        console.debug("Plausible event", ...args)
      }
    }
  }

  return Plausible({
    hashMode: true,
    domain: getTargetDomain()
  })
}

const $plausible = initPlausible()

const PLAUSIBLE_PAGE_VIEW_EVENT = "pageview"

export const sendPageView = (): void => {
  $plausible.trackEvent(PLAUSIBLE_PAGE_VIEW_EVENT)
}

const $sendAnalytics = (
  name: string,
  feature: string,
  action: string,
  route: RouteLocationNormalizedLoaded
): void => {
  const { projectConfig } = useProjectConfigStore()
  const userGroups = $user?.groups ? [...$user.groups].sort().join(",") : "[]"
  const projectId = projectConfig?.id || "None"
  const urlPath = route.path || "None"
  const component = (route.meta.component as string) || "None"
  const panel = (route.meta.panel as string) || "None"
  $plausible.trackEvent(name, {
    props: {
      project_id: projectId,
      user_groups: `[${userGroups}]`,
      feature,
      action,
      panel,
      component,
      blob: `${projectId}__[${userGroups}]__${feature}__${action}__${panel}__${component}__${urlPath}`
    }
  })
}

const plugin = {
  install(app: App) {
    app.config.globalProperties.$sendAnalytics = $sendAnalytics
  }
}

declare module "@vue/runtime-core" {
  //Bind to `this` keyword
  interface ComponentCustomProperties {
    $sendAnalytics: typeof $sendAnalytics
  }
}

export default plugin
export { $sendAnalytics }
