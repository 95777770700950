<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 5.85714C12.9941 5.85714 13.8 4.99369 13.8 3.92857C13.8 2.86345 12.9941 2 12 2C11.0059 2 10.2 2.86345 10.2 3.92857C10.2 4.99369 11.0059 5.85714 12 5.85714Z"
      fill="currentColor" />
    <path
      d="M9 8.42857C9 7.71849 9.53726 7.14286 10.2 7.14286H13.8C14.4627 7.14286 15 7.71849 15 8.42857V12.2857C15 12.9958 14.4627 13.5714 13.8 13.5714V18.7143C13.8 19.4244 13.2627 20 12.6 20H11.4C10.7373 20 10.2 19.4244 10.2 18.7143V13.5714C9.53726 13.5714 9 12.9958 9 12.2857V8.42857Z"
      fill="currentColor" />
    <path
      d="M8.45885 17.0479C8.74977 17.0197 9 17.2501 9 17.5424C9 17.8029 8.80012 18.0194 8.54088 18.0446C7.25403 18.1699 6.11007 18.3622 5.1768 18.601C4.31548 18.8214 3.68981 19.0674 3.30343 19.3011C3.19452 19.367 3.19452 19.5158 3.30343 19.5817C3.68981 19.8154 4.31548 20.0614 5.1768 20.2818C6.88432 20.7186 9.29712 20.9999 12 20.9999C14.7029 20.9999 17.1157 20.7186 18.8232 20.2818C19.6845 20.0614 20.3102 19.8154 20.6966 19.5817C20.8055 19.5158 20.8055 19.367 20.6966 19.3011C20.3102 19.0674 19.6845 18.8214 18.8232 18.601C17.8899 18.3622 16.746 18.1699 15.4591 18.0446C15.1999 18.0194 15 17.8029 15 17.5424C15 17.2501 15.2502 17.0197 15.5412 17.0479C19.3162 17.4137 22 18.3473 22 19.4414C22 20.8544 17.5228 21.9999 12 21.9999C6.47715 21.9999 2 20.8544 2 19.4414C2 18.3473 4.68381 17.4137 8.45885 17.0479Z"
      fill="currentColor" />
  </svg>
</template>
