import * as d3Array from "d3-array"

type FunctionGenerator = (item) => string
type Reducer = (items) => any

export default {
  getMin(array: any[], key: string | FunctionGenerator) {
    return d3Array.min(array, typeof key === "string" ? (item) => item[key] : key)
  },
  getMax(array: any[], key: string | FunctionGenerator) {
    return d3Array.max(array, typeof key === "string" ? (item) => item[key] : key)
  },
  getMean(array: number[]) {
    return array.reduce((a, b) => a + b) / array.length
  },
  sum(array: any[], key: string | FunctionGenerator) {
    return d3Array.sum(array, typeof key === "string" ? (item) => item[key] : key)
  },
  nest(
    array: any[],
    iterator1?: FunctionGenerator,
    iterator2?: FunctionGenerator,
    iterator3?: FunctionGenerator
  ): { key: any; values: any }[] {
    if (iterator1 && iterator2 && iterator3) {
      return Array.from(d3Array.group(array, iterator1, iterator2, iterator3), ([key, values]) => ({
        key,
        values
      }))
    } else if (iterator1 && iterator2) {
      return Array.from(d3Array.group(array, iterator1, iterator2), ([key, values]) => ({
        key,
        values
      }))
    } else if (iterator1) {
      return Array.from(d3Array.group(array, iterator1), ([key, values]) => ({
        key,
        values
      }))
    }
    return array
  },
  rollup(
    array: any[],
    reducer: Reducer,
    iterator1?: FunctionGenerator,
    iterator2?: FunctionGenerator,
    iterator3?: FunctionGenerator
  ) {
    if (iterator1 && iterator2 && iterator3) {
      return Array.from(
        d3Array.rollup(array, reducer, iterator1, iterator2, iterator3),
        ([key, values]) => ({
          key,
          values
        })
      )
    } else if (iterator1 && iterator2) {
      return Array.from(d3Array.rollup(array, reducer, iterator1, iterator2), ([key, values]) => ({
        key,
        values
      }))
    } else if (iterator1) {
      return Array.from(d3Array.rollup(array, reducer, iterator1), ([key, values]) => ({
        key,
        values
      }))
    }
    return array
  },
  flatRollup(
    array: any[],
    reducer: Reducer,
    iterator1?: FunctionGenerator,
    iterator2?: FunctionGenerator,
    iterator3?: FunctionGenerator
  ) {
    if (iterator1 && iterator2 && iterator3) {
      return Array.from(
        d3Array.flatRollup(array, reducer, iterator1, iterator2, iterator3),
        ([key, values]) => ({
          key,
          values
        })
      )
    } else if (iterator1 && iterator2) {
      return Array.from(
        d3Array.flatRollup(array, reducer, iterator1, iterator2),
        ([key, values]) => ({
          key,
          values
        })
      )
    } else if (iterator1) {
      return Array.from(d3Array.flatRollup(array, reducer, iterator1), ([key, values]) => ({
        key,
        values
      }))
    }
    return array
  },
  range: d3Array.range,
  extent(array: any[], key: string | FunctionGenerator) {
    return d3Array.extent(array, typeof key === "string" ? (item) => item[key] : key)
  },
  ascending(a: any, b: any) {
    return d3Array.ascending(a, b)
  },
  descending(a: any, b: any) {
    return d3Array.descending(a, b)
  }
}
