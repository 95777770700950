<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5983 17.6237L10.9377 11.2832C10.7452 10.9499 10.9858 10.5332 11.3707 10.5332L18.0334 10.5332C18.0187 10.3695 17.9621 10.205 17.8566 10.0537L12.7766 2.80366C12.3766 2.23366 11.5366 2.23366 11.1366 2.80366L6.09659 10.0537C5.63659 10.7137 6.11659 11.6237 6.92659 11.6237H6.96659L4.06659 16.0837C3.62659 16.7437 4.10659 17.6237 4.90659 17.6237H9.98659V19.6437C9.98659 20.7337 10.8766 21.6237 11.9666 21.6237C13.0566 21.6237 13.9466 20.7337 13.9466 19.6437V17.6237H14.5983Z"
        fill="currentColor" />
      <path
        d="M13.558 12.7966C13.3656 12.4632 13.6061 12.0466 13.991 12.0466L21.3522 12.0466C21.7371 12.0466 21.9777 12.4632 21.7853 12.7966L18.1046 19.1716C17.9122 19.5049 17.4311 19.5049 17.2386 19.1716L13.558 12.7966Z"
        fill="currentColor" />
    </g>
  </svg>
</template>
