<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9662 14.4721H16.1762L15.8962 14.2021C17.0962 12.8021 17.7162 10.8921 17.3762 8.86207C16.9062 6.08207 14.5862 3.87207 11.7962 3.52207C8.00621 3.05207 4.76621 5.85207 4.49621 9.47207H6.51621C6.75621 7.35207 8.43621 5.67207 10.5762 5.49207C13.1162 5.27207 15.4662 7.42207 15.4662 9.97207C15.4662 12.4621 13.4562 14.4721 10.9662 14.4721C10.7962 14.4721 10.6362 14.4421 10.4662 14.4221V16.4421C10.4662 16.4421 10.4662 16.4421 10.4762 16.4521C12.2762 16.5821 13.9462 15.9821 15.1962 14.9021L15.4662 15.1821V15.9721L19.7162 20.2221C20.1262 20.6321 20.7962 20.6321 21.2062 20.2221C21.6162 19.8121 21.6162 19.1421 21.2062 18.7321L16.9662 14.4721Z"
      fill="currentColor" />
    <path
      d="M7.58621 11.6421L5.46621 13.7621L3.34621 11.6421C3.14621 11.4421 2.83621 11.4421 2.63621 11.6421C2.43621 11.8421 2.43621 12.1521 2.63621 12.3521L4.75621 14.4721L2.63621 16.5921C2.43621 16.7921 2.43621 17.1021 2.63621 17.3021C2.83621 17.5021 3.14621 17.5021 3.34621 17.3021L5.46621 15.1821L7.58621 17.3021C7.78621 17.5021 8.09621 17.5021 8.29621 17.3021C8.49621 17.1021 8.49621 16.7921 8.29621 16.5921L6.17621 14.4721L8.29621 12.3521C8.49621 12.1521 8.49621 11.8421 8.29621 11.6421C8.09621 11.4521 7.78621 11.4521 7.58621 11.6421Z"
      fill="currentColor" />
  </svg>
</template>
