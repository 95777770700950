import type { IntlShape } from "@formatjs/intl"
import type { FormatXMLElementFn, PrimitiveType } from "intl-messageformat"

/**
 * Shared instance of formatjs intl.
 */
export const intlSingleton: {
  $intl: IntlShape | null
} = {
  $intl: null
}

export const useTranslate = () => {
  // Used in composable, `intlSingleton.$intl` will always be set
  const intl = intlSingleton.$intl as IntlShape

  const translate = (
    descriptor: { defaultMessage: string; description?: { tags?: string; note?: string } },
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
  ): string => {
    /*
      Those tags will be recognized when we use formatMessage for rich text formatting
    */
    const htmlTags = ["i", "b", "u", "s"]
    const htmlTagsRenderingFunctions = {}

    htmlTags.forEach((tag) => {
      htmlTagsRenderingFunctions[tag] = (text: string[]) => `<${tag}>${text}</${tag}>`
    })

    return intl.formatMessage(
      { ...descriptor, id: descriptor.defaultMessage },
      {
        ...htmlTagsRenderingFunctions,
        ...values
      }
    )
  }

  return {
    translate
  }
}
