import { EventHubPlugin } from "@/src/plugins/vue_event_hub"
import DeepkiAxios from "@/src/utils/axios"
import showErrors from "@/src/utils/errors"
import utils from "@/src/utils/utils"

import { isAxiosError, type AxiosError } from "axios"

import type { App } from "vue"

const toastError = (message: string): void => {
  EventHubPlugin.$emit("toast", {
    title: message,
    text: "",
    icon: "fa-exclamation",
    deletable: true,
    iconColor: "error"
  })
}

/**
 * Report error then rethrow it in order to allow chain .catch().
 *
 * It allows to report the error, but still have the .catch for further handling, for example
 *
 * @example
 * $http.get("/foo/bar").catch($reportError).catch((reason) => {
 *   // the original error that have been rethrown
 * })
 */
export const $reportError = <T extends AxiosError>(error: T): T => {
  if (isAxiosError(error) && error.name !== "CanceledError" && error.response) {
    showErrors(error.response, toastError)
  }
  throw error
}

/**
 *
 * @param {AxiosError} error - Error that will be pattern matched
 */
export const $httpErrorHandler = (error: unknown): undefined => {
  if (isAxiosError(error) && error.name === "CanceledError") {
    return
  }

  if (isAxiosError(error) && error.response) {
    showErrors(error.response, toastError)
  } else {
    throw error
  }
}

export const $doubleEncodeUrl = utils.doubleEncodeUrl

export const $http = DeepkiAxios

const plugin = {
  install(app: App) {
    app.config.globalProperties.$httpErrorHandler = $httpErrorHandler
    app.config.globalProperties.$http = $http
    app.config.globalProperties.$errorHandler = showErrors
    app.config.globalProperties.$doubleEncodeUrl = $doubleEncodeUrl
  }
}

export default plugin
