<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.4614 10.0095C19.3628 10.1553 19.3043 10.3245 19.2921 10.5001C19.2798 10.6757 19.314 10.8514 19.3914 11.0095C19.6396 11.5888 19.8143 12.1969 19.9114 12.8195C20.1688 14.4297 19.9281 16.08 19.2214 17.5495C19.1356 17.7107 19.0073 17.8453 18.8504 17.9387C18.6935 18.0322 18.514 18.0809 18.3314 18.0795H5.68141C5.49778 18.0799 5.31759 18.0297 5.1606 17.9345C5.00361 17.8392 4.87588 17.7026 4.79141 17.5395C4.15129 16.241 3.87555 14.793 3.99359 13.3501C4.11163 11.9071 4.61903 10.5233 5.46169 9.34602C6.30435 8.16876 7.45068 7.24224 8.77849 6.66522C10.1063 6.0882 11.5658 5.88232 13.0014 6.06953C13.7278 6.1549 14.4383 6.34347 15.1114 6.62952C15.2695 6.7069 15.4452 6.74117 15.6208 6.72888C15.7964 6.71659 15.9656 6.65817 16.1114 6.55953C16.2633 6.45947 16.385 6.31989 16.4635 6.1558C16.5419 5.99172 16.5741 5.80934 16.5566 5.62831C16.5391 5.44728 16.4726 5.27444 16.3642 5.12841C16.2558 4.98237 16.1096 4.86866 15.9414 4.79953C14.0096 3.97118 11.8659 3.77261 9.81482 4.23202C7.76373 4.69143 5.90955 5.78546 4.51566 7.35871C3.12177 8.93195 2.25906 10.9044 2.05006 12.9959C1.84106 15.0874 2.2964 17.1916 3.35141 19.0095C3.52592 19.3118 3.7765 19.5631 4.07824 19.7386C4.37998 19.914 4.72238 20.0074 5.07141 20.0095H18.9214C19.2738 20.0109 19.6204 19.9192 19.9259 19.7436C20.2315 19.568 20.4852 19.3147 20.6614 19.0095C21.4338 17.671 21.8854 16.1716 21.9808 14.6291C22.0763 13.0867 21.8129 11.5431 21.2114 10.1195C21.1352 9.95728 21.0168 9.8185 20.8686 9.7177C20.7204 9.6169 20.5479 9.55778 20.369 9.54654C20.1901 9.53529 20.0115 9.57233 19.8518 9.65378C19.6921 9.73523 19.5573 9.8581 19.4614 10.0095Z"
      fill="currentColor" />
    <path
      d="M10.5914 12.5995C10.2161 12.9748 10.0053 13.4838 10.0053 14.0145C10.0053 14.2773 10.0571 14.5375 10.1576 14.7803C10.2582 15.0231 10.4056 15.2437 10.5914 15.4295C10.7772 15.6153 10.9978 15.7627 11.2406 15.8633C11.4834 15.9639 11.7436 16.0156 12.0064 16.0156C12.5371 16.0156 13.0461 15.8048 13.4214 15.4295L19.0814 6.93952L10.5914 12.5995Z"
      fill="currentColor" />
  </svg>
</template>
