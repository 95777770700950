import type { Icon } from "../domains/volta/components/icons/volta-icon"

export const faToVoltaMapping = {
  "dr-alert-overconsumption-monthly": "overconsumption-montly",
  "dr-alert-overconsumption": "overconsumption",
  "dr-alert-overloads": "overload",
  "dr-building": "business",
  "dr-calender": "calendar-month",
  "dr-chart-down": "trending-down",
  "dr-decrease": "trending-down",
  "dr-disconnection": "link-off",
  "dr-entypo-flow-tree": "flow-tree",
  "dr-heater": "heater",
  "dr-historic-over-conso": "data-exploration",
  "dr-icon-surface": "surface",
  "dr-invoices": "invoices",
  "dr-leak": "water-damage",
  "dr-low-flow": "priority-low",
  "dr-maki-fuel": "fuel",
  "dr-meter": "electric-meter",
  "dr-no-doc": "no-document",
  "dr-no-meter": "no-meter",
  "dr-no-signal": "wifi-off",
  "dr-not-send": "not-sent",
  "dr-reverse-flow": "sync"
} as const satisfies Record<string, Icon>
