<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.20618 5.19873H6.20618V6.19873C6.20618 7.29873 7.10618 8.19873 8.20618 8.19873H14.2062C15.3062 8.19873 16.2062 7.29873 16.2062 6.19873V5.19873H18.2062V10.1987H20.2062V5.19873C20.2062 4.09873 19.3062 3.19873 18.2062 3.19873H14.0262C13.6062 2.03873 12.5062 1.19873 11.2062 1.19873C9.90618 1.19873 8.80618 2.03873 8.38618 3.19873H4.20618C3.10618 3.19873 2.20618 4.09873 2.20618 5.19873V19.1987C2.20618 20.2987 3.10618 21.1987 4.20618 21.1987H9.20618V19.1987H4.20618V5.19873ZM11.2062 3.19873C11.7562 3.19873 12.2062 3.64873 12.2062 4.19873C12.2062 4.74873 11.7562 5.19873 11.2062 5.19873C10.6562 5.19873 10.2062 4.74873 10.2062 4.19873C10.2062 3.64873 10.6562 3.19873 11.2062 3.19873Z"
      fill="currentColor" />
    <path
      d="M21.5062 21.0987L19.5062 19.0987C20.0862 18.0887 20.4562 16.8687 20.0162 15.4487C19.4862 13.7287 17.9762 12.3987 16.1762 12.2287C13.3062 11.9487 10.9462 14.2987 11.2262 17.1787C11.4062 18.9687 12.7262 20.4887 14.4462 21.0187C15.8762 21.4587 17.0862 21.0887 18.0962 20.5087L20.0962 22.5087C20.4862 22.8987 21.1062 22.8987 21.4962 22.5087C21.8862 22.1187 21.8962 21.4887 21.5062 21.0987ZM15.7062 19.1987C14.3062 19.1987 13.2062 18.0987 13.2062 16.6987C13.2062 15.2987 14.3062 14.1987 15.7062 14.1987C17.1062 14.1987 18.2062 15.2987 18.2062 16.6987C18.2062 18.0987 17.1062 19.1987 15.7062 19.1987Z"
      fill="currentColor" />
  </svg>
</template>
