import { getIncludedJsonData } from "@/src/core/init"

import type { App } from "vue"

/****** Dependency to Flask auto injected data ******/
const jsonData = getIncludedJsonData()

const $flag = (name: string, activeByDefault = false) => jsonData.flags?.[name] || activeByDefault

const plugin = {
  install(app: App) {
    app.config.globalProperties.$flag = $flag
  }
}

export default plugin
export { $flag }
