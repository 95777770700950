<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.25 2.7474H5.25C4.7 2.7474 4.25 3.1974 4.25 3.7474C4.25 4.2974 4.7 4.7474 5.25 4.7474H9.25C9.8 4.7474 10.25 4.2974 10.25 3.7474C10.25 3.1974 9.8 2.7474 9.25 2.7474Z"
      fill="currentColor" />
    <path
      d="M5.25 6.7474H2.25C1.7 6.7474 1.25 7.1974 1.25 7.7474C1.25 8.2974 1.7 8.7474 2.25 8.7474H5.25C5.8 8.7474 6.25 8.2974 6.25 7.7474C6.25 7.1974 5.8 6.7474 5.25 6.7474Z"
      fill="currentColor" />
    <path
      d="M4.25 20.7474H7.25C7.8 20.7474 8.25 20.2974 8.25 19.7474C8.25 19.1974 7.8 18.7474 7.25 18.7474H4.25C3.7 18.7474 3.25 19.1974 3.25 19.7474C3.25 20.2974 3.7 20.7474 4.25 20.7474Z"
      fill="currentColor" />
    <path
      d="M13.98 10.3574C14.73 10.5874 15.28 11.1374 15.55 11.8174L19.82 4.7074C20.47 3.6274 20.12 2.2274 19.04 1.5774C18.17 1.0574 17.05 1.1674 16.31 1.8674L12.88 5.0774C12.48 5.4474 12.25 5.9774 12.25 6.5274V10.4574C12.61 10.3074 13.23 10.1274 13.98 10.3574Z"
      fill="currentColor" />
    <path
      d="M10.86 12.0174C11.02 11.4974 11.34 11.0574 11.75 10.7474H3.53C2.27 10.7474 1.25 11.7674 1.25 13.0274C1.25 14.0474 1.92 14.9374 2.9 15.2174L7.41 16.5074C7.94 16.6574 8.51 16.5874 8.99 16.2974L11.68 14.6874C10.91 14.0674 10.55 13.0174 10.86 12.0174Z"
      fill="currentColor" />
    <path
      d="M22.46 18.3574L20.18 14.2574C19.91 13.7774 19.45 13.4274 18.92 13.2874L15.74 12.4874C15.77 12.8074 15.74 13.1474 15.64 13.4774C15.32 14.5374 14.36 15.2474 13.25 15.2474C12.64 15.2474 12.26 15.0274 12.25 15.0274V20.7474C11.15 20.7474 10.25 21.6474 10.25 22.7474H16.25C16.25 21.6474 15.35 20.7474 14.25 20.7474V16.4674L18.86 21.0774C19.75 21.9674 21.19 21.9674 22.08 21.0774C22.8 20.3574 22.96 19.2474 22.46 18.3574Z"
      fill="currentColor" />
    <path
      d="M12.81 14.1774C13.6 14.4174 14.44 13.9774 14.68 13.1774C14.92 12.3874 14.48 11.5474 13.68 11.3074C12.89 11.0674 12.05 11.5074 11.81 12.3074C11.57 13.0974 12.02 13.9374 12.81 14.1774Z"
      fill="currentColor" />
  </svg>
</template>
