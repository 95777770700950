<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 6.5C1.45 6.5 1 6.95 1 7.5V19.5C1 20.6 1.9 21.5 3 21.5H19C19.55 21.5 20 21.05 20 20.5C20 19.95 19.55 19.5 19 19.5H3V7.5C3 6.95 2.55 6.5 2 6.5Z"
      fill="currentColor" />
    <path
      d="M21 4.5H14L12.59 3.09C12.21 2.71 11.7 2.5 11.17 2.5H7C5.9 2.5 5.01 3.4 5.01 4.5L5 15.5C5 16.6 5.9 17.5 7 17.5H21C22.1 17.5 23 16.6 23 15.5V6.5C23 5.4 22.1 4.5 21 4.5Z"
      fill="currentColor" />
  </svg>
</template>
