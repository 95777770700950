/* eslint-disable camelcase */
import colorbrewer from "colorbrewer"
import { hcl } from "d3-color"
import { interpolateLab } from "d3-interpolate"
import { scaleLinear } from "d3-scale"

import arrayUtils from "@/src/utils/array-utils"

/* colors factories */

const palettes = {
  ...colorbrewer,
  matthieu: {
    12: [
      "#a6cee3",
      "#ff7f00",
      "#b2df8a",
      "#1f78b4",
      "#fdbf6f",
      "#33a02c",
      "#cab2d6",
      "#6a3d9a",
      "#fb9a99",
      "#e31a1c",
      "#fbe064",
      "#b15928"
    ]
  },

  epc: {
    7: ["#349144", "#6fb444", "#8dc140", "#dbdf37", "#f4d628", "#eeaa2c", "#e5722a"]
  },

  manuela: {
    27: [
      "#3E7DC1",
      "#F9AF3F",
      "#BC3C3E",
      "#3DACA7",
      "#ED7E74",
      "#754861",
      "#D16B34",
      "#114B5F",
      "#69CE9A",
      "#72A0D1",
      "#f4c884",
      "#f9b1b2",
      "#95D1CF",
      "#F19D96",
      "#A07385",
      "#E48C5B",
      "#4F7885",
      "#93DBB3",
      "#1D3958",
      "#72501D",
      "#561C1D",
      "#1C4F4C",
      "#6C3A35",
      "#3D2A34",
      "#8E5739",
      "#1B2A30",
      "#527A62"
    ]
  },

  giulia: {
    14: [
      "#009686",
      "#CF0054",
      "#F1B500",
      "#F16700",
      "#42B2A6",
      "#E2538D",
      "#953F00",

      "#5fd3c5", // j'ai rajouter des variations plus clairs parcequ'il me manquait des couleurs...
      "#ff3688",
      "#ff9f58",
      "#87d3cb",
      "#f1aac7",
      "#fb6a00"
    ]
  },

  "small-YlGnBu": {
    6: ["#7fcdbb", "#41b6c4", "#1d91c0", "#225ea8", "#253494", "#081d58"]
  },

  // google chart colors
  google: {
    20: [
      "#3366CC",
      "#DC3912",
      "#FF9900",
      "#109618",
      "#990099",
      "#3B3EAC",
      "#0099C6",
      "#DD4477",
      "#66AA00",
      "#B82E2E",
      "#316395",
      "#994499",
      "#22AA99",
      "#AAAA11",
      "#6633CC",
      "#E67300",
      "#8B0707",
      "#329262",
      "#5574A6",
      "#3B3EAC"
    ]
  },

  default: {
    13: [
      "#6BBAFE",
      "#129a94",
      "#ff7f00",
      "#b2df8a",
      "#8c9395",
      "#A33456",
      "#33a02c",
      "#cab2d6",
      "#6a3d9a",
      "#fb9a99",
      "#e31a1c",
      "#fbe064",
      "#b15928"
    ]
  },

  defaultGreen: {
    13: [
      "#129a94",
      "#6BBAFE",
      "#ff7f00",
      "#b2df8a",
      "#8c9395",
      "#A33456",
      "#33a02c",
      "#cab2d6",
      "#6a3d9a",
      "#fb9a99",
      "#e31a1c",
      "#fbe064",
      "#b15928"
    ],
    26: [
      "#129a94",
      "#6BBAFE",
      "#ff7f00",
      "#b2df8a",
      "#8c9395",
      "#A33456",
      "#33a02c",
      "#cab2d6",
      "#6a3d9a",
      "#fb9a99",
      "#e31a1c",
      "#fbe064",
      "#b15928",
      "#0e7878",
      "#5792c8",
      "#c87800",
      "#8cb46e",
      "#c8c8c8",
      "#822a46",
      "#28821e",
      "#a08ec2",
      "#503178",
      "#c87878",
      "#be1414",
      "#c8b450",
      "#8c4620"
    ]
  },

  defaultNum: {
    9: colorbrewer.RdYlGn[9].concat([]).reverse()
  },

  wesanderson_FantasticFox: {
    5: ["#DD8D29", "#E2D200", "#46ACC8", "#E58601", "#B40F20"]
  },
  wesanderson_Darjeeling: {
    5: ["#FF0000", "#00A08A", "#F2AD00", "#F98400", "#5BBCD6"]
  },
  wesanderson_Darjeeling2: {
    5: ["#ECCBAE", "#046C9A", "#D69C4E", "#ABDDDE", "#000000"]
  },

  fluidColors: {
    18: [
      "#bde1ff", // water light
      "#006cc5", // water dark
      "#c1d1d7", // carbon light
      "#5c818f", // carbon dark
      "#ffd5b3", // gas light
      "#ef6c00", // gas dark
      "#ffecb3", // electricity light
      "#ffc107", // electricity dark
      "#d0beb6", // waste light
      "#9b7767", // waste dark
      "#dcdbeb", // district cooling light
      "#716ace", // district cooling dark
      "#e8b8ab", // district heating light
      "#b34c31", // district heating dark
      "#4e4e4e", // oil light
      "#d5d5d5", // oil dark
      "#b5f3ef", // multiple fluids light
      "#1ba19a" // multiple fluids dark
    ]
  },

  graphColors: {
    24: [
      "#718ff9",
      "#1141ea",
      "#f4d5f9",
      "#9f5baa",
      "#f9c495",
      "#e8740d",
      "#9ec7a5",
      "#51bc65",
      "#c7baff",
      "#5a21a3",
      "#00b4d2",
      "#12616e",
      "#ce8ca0",
      "#cc3c67",
      "#edd587",
      "#f8c935",
      "#c8cb85",
      "#335739",
      "#ea9090",
      "#d93939",
      "#a88519",
      "#642b3c",
      "#f1b7fb",
      "#b231ea"
    ]
  },

  pathwayEnergyLines: {
    5: ["#12616e", "#00b4d2", "#9f5baa"]
  },
  pathwayCarbonLines: {
    5: ["#cc3c67", "#b231ea", "#642b3c", "#1141ea"]
  },

  climateRisks: {
    6: [
      "rgba(254, 228, 0, 0.1)",
      "#fee400",
      "rgba(255, 177, 21, 0.6)",
      "#ffb115",
      "#f56913",
      "#ea1711"
    ]
  },

  gen_palette: {
    ggplot: function (n) {
      const hues = arrayUtils.range(15, 375, (375 - 15) / n)
      return hues.map((h) => {
        return hcl(h, 100, 65).toString()
      })
    }
  },

  interpolate(colors, n) {
    const indexScale = scaleLinear()
      .domain([0, n - 1])
      .range([0, colors.length - 1])

    const interpolates = arrayUtils.range(colors.length - 1).map((i) => {
      return scaleLinear()
        .domain([i, i + 1])
        .range([colors[i], colors[i + 1]])
        .interpolate((a, b) => interpolateLab(String(a), String(b)))
    })

    return arrayUtils.range(n).map((d) => {
      const i = indexScale(d)
      return [...interpolates, () => colors[colors.length - 1]][Math.floor(i)](i)
    })
  },

  getFluidColor(fluid: string, colorType: string): string {
    const mapFluidColorIndex = {
      water: 0,
      carbon: 2,
      gas: 4,
      electricity: 6,
      waste: 8,
      districtCooling: 10,
      districtHeating: 12,
      oil: 14,
      multipleFluids: 16
    }
    let index = mapFluidColorIndex[fluid] ? mapFluidColorIndex[fluid] : 0
    index = colorType == "dark" ? index + 1 : index
    const fluidColorsKey = Math.max(
      ...Object.keys(palettes.fluidColors)
        .map((t) => Number(t))
        .filter((key) => !isNaN(key))
    )
    const fluidColors = palettes.fluidColors[fluidColorsKey]
    return fluidColors[index]
  },

  getGraphColors(colorType: string, length?: number): string[] {
    const graphColorsKey = Math.max(
      ...Object.keys(palettes.graphColors)
        .map((t) => Number(t))
        .filter((key) => !isNaN(key))
    )
    const graphColors = palettes.graphColors[graphColorsKey]
    let colors = []
    let colorPalette
    switch (colorType) {
      case "light":
        colorPalette = graphColors.filter((_, index) => index % 2 == 0)
        break
      case "dark":
        colorPalette = graphColors.filter((_, index) => index % 2 == 1)
        break
      default:
        colorPalette = graphColors
        break
    }
    if (length != null) {
      let quotient = Math.floor(length / colorPalette.length)
      const rest = length % colorPalette.length
      while (quotient > 0) {
        colors = colors.concat(colorPalette)
        quotient = quotient - 1
      }
      colors = colors.concat(colorPalette.slice(0, rest))
    } else {
      colors = colorPalette
    }
    return colors
  }
}

for (const pal in palettes) {
  if (palettes[pal]) {
    let max = 0
    for (const num in palettes[pal]) {
      if (palettes[pal][num] && Number(num)) {
        max = Math.max(Number(num), max)
      }
    }
    palettes[pal].max = max
  }
}

export default palettes

export const multipleFluidsColors = { light: "#b5f3ef", dark: "#1ba19a" }
