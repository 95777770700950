<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2917 4.70589C17.9028 4.31699 17.9028 3.68059 18.2917 3.29168C18.6806 2.90277 19.317 2.90277 19.7059 3.29168L22.2414 5.82721C22.6303 6.21612 22.6303 6.85252 22.2414 7.24143L19.7059 9.77696C19.317 10.1659 18.6806 10.1659 18.2917 9.77696C17.9028 9.38805 17.9028 8.75166 18.2917 8.36275L19.1231 7.53134L19.1923 7.4499H18.5C17.2535 7.44991 16.0581 7.94509 15.1766 8.8265C14.2952 9.70792 13.8 10.9034 13.8 12.1499C13.8 12.1499 13.8 13 12.9 13C12 13 12 12.1499 12 12.1499C12 10.426 12.6848 8.77269 13.9038 7.55371C15.1228 6.33472 16.7761 5.6499 18.5 5.6499H19.2257L19.1231 5.5373L18.2917 4.70589Z"
      fill="currentColor" />
    <path
      d="M18.2222 17.3333C18.2222 17.8222 17.8222 18.2222 17.3333 18.2222H6.66667C6.17778 18.2222 5.77778 17.8222 5.77778 17.3333V6.66667C5.77778 6.17778 6.17778 5.77778 6.66667 5.77778H10.1111C10.6 5.77778 11 5.37778 11 4.88889C11 4.4 10.6 4 10.1111 4H5.77778C4.79111 4 4 4.8 4 5.77778V18.2222C4 19.2 4.8 20 5.77778 20H18.2222C19.2 20 20 19.2 20 18.2222V13.8889C20 13.4 19.6 13 19.1111 13C18.6222 13 18.2222 13.4 18.2222 13.8889V17.3333Z"
      fill="currentColor" />
  </svg>
</template>
