import { MENU_TYPES } from "./consts"

import type { Menu, PanelTypeMenu, SectionTypeMenu } from "@/src/typings/globals"

export const isPanelType = (menuItem: Menu): menuItem is PanelTypeMenu => {
  return menuItem.type === MENU_TYPES.PANEL
}

export const isSectionType = (menuItem: Menu): menuItem is SectionTypeMenu => {
  return menuItem.type === MENU_TYPES.SECTION
}
