import { $http } from "@/src/plugins/vue_axios"

function storeGlobals(globals: Record<string, any>): void {
  for (const key in globals) {
    globalThis[key] = globals[key]
  }
}

export async function setTheme(theme: string): Promise<void> {
  try {
    const themeName = theme === "default-dark-menu" ? "default" : theme
    const response = await $http.get(`/static/themes/${themeName}.css`)

    const headElement = document.querySelector("head")
    if (headElement) {
      headElement.innerHTML += `<style type="text/css">${response.data}</style>`
    }
  } catch (error) {
    console.error(error)
    setTheme("default")
  }
}

const cache: { includedJsonData?: Record<string, any> } = { }
export function getIncludedJsonData(): Record<string, any> {
  if (cache.includedJsonData) {
    return cache.includedJsonData
  }
  try {
    const jsonHolderElement = document.querySelector<HTMLElement>("[data-id=global-data]")
    if (jsonHolderElement) {
      const jsonResult = JSON.parse(jsonHolderElement.innerText)
      if (typeof jsonResult === "object" && !Array.isArray(jsonResult)) {
        cache.includedJsonData = jsonResult
        return jsonResult
      }

      cache.includedJsonData = {}
      return {}
    }
    cache.includedJsonData = {}
    return {}
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const includeJsonData = (): void => {
  const jsonData = getIncludedJsonData()
  for (const key in jsonData) {
    /**
     * cache_buster
     * env_level
     * environment
     * flags
     * load_user
     * locale
     * main_vue_component
     * project
     * sentry_dsn
     * sentry_dsn_js
     * user_agent
     * version
     */
    globalThis[key] = jsonData[key]
  }
}

export const init = (globals: Record<string, any>): void => {
  // FIXME: storeglobals is storing same data as includeJsondata, but with csrf_token...
  storeGlobals(globals)
}
