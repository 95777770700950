import { ref, type App, type Component } from "vue"

const refContainer = ref()
export const modalContainerSymbol = Symbol()

export default {
  install(app: App) {
    app.provide(modalContainerSymbol, refContainer)

    app.config.globalProperties.$modals = ModalPlugin
  }
}

export interface ModalPlugin {
  close: () => Promise<void>

  open: (
    modalComponent: string | Component,
    modalProps: { [key: string]: any },
    callback?: (response?: any) => void,
    listeners?: { [key: string]: (arg0?: any) => void }
  ) => void

  setModalProp: (propName: string, propValue: any) => void
  alertPopup: (
    callback: (response?: any) => void,
    modalListeners: { [key: string]: (arg0?: any) => void },
    title: string,
    text: string,
    actionType?: string,
    actionText?: string,
    icon?: string,
    iconColor?: string,
    backgroundColor?: string,
    isHtml?: boolean,
    html?: string
  ) => void
  confirmationPopup: (
    callback: (response?: any) => void,
    modalListeners: { [key: string]: (arg0?: any) => void },
    title: string,
    text: string,
    actionType?: string,
    actionText?: string,
    cancelText?: string,
    icon?: string,
    iconColor?: string,
    backgroundColor?: string,
    isHtml?: boolean,
    html?: string
  ) => void
}

const ModalPlugin: ModalPlugin = {
  close() {
    return refContainer.value.close()
  },

  open(
    modalComponent: string | Component,
    modalProps: { [key: string]: any },
    callback?: () => void,
    listeners?: { [key: string]: (arg0?: any) => void }
  ) {
    return refContainer.value.open(modalComponent, modalProps, callback, listeners)
  },

  setModalProp(propName: string, propValue: any) {
    return refContainer.value.setModalProp(propName, propValue)
  },

  alertPopup(
    callback: () => void,
    modalListeners: { [key: string]: (arg0?: any) => void },
    title: string,
    text: string,
    actionType?: string,
    actionText?: string,
    icon?: string,
    iconColor?: string,
    backgroundColor?: string,
    isHtml?: boolean,
    html?: string
  ) {
    return refContainer.value.alertPopup(
      callback,
      modalListeners,
      title,
      text,
      actionType,
      actionText,
      icon,
      iconColor,
      backgroundColor,
      isHtml,
      html
    )
  },

  confirmationPopup(
    callback: () => void,
    modalListeners: { [key: string]: (arg0?: any) => void },
    title: string,
    text: string,
    actionType?: string,
    actionText?: string,
    cancelText?: string,
    icon?: string,
    iconColor?: string,
    backgroundColor?: string,
    isHtml?: boolean,
    html?: string
  ) {
    return refContainer.value.confirmationPopup(
      callback,
      modalListeners,
      title,
      text,
      actionType,
      actionText,
      cancelText,
      icon,
      iconColor,
      backgroundColor,
      isHtml,
      html
    )
  }
}

const $modals = ModalPlugin

declare module "@vue/runtime-core" {
  //Bind to `this` keyword
  interface ComponentCustomProperties {
    $modals: typeof $modals
  }
}

export { $modals }
