<template>
  <div class="input-col">
    <input
      v-if="props.type === 'num'"
      :id="props.id"
      v-model.number="input"
      :class="inputClasses"
      type="number"
      :placeholder="props.placeholder"
      :min="props.min"
      :max="props.max"
      :disabled="props.disabled" />
    <input
      v-else
      :id="props.id"
      v-model="input"
      :autocomplete="name ? 'on' : 'off'"
      :class="inputClasses"
      :min="props.min"
      :type="displayInputValue"
      :placeholder="props.placeholder"
      :name="props.name"
      :disabled="props.disabled"
      @keyup.enter="onEnter"
      @keyup="(event) => emit('keyup', event)"
      @change="(event) => emit('change', event)"
      @focus="(event) => emit('focus', event)"
      @focusin="(event) => emit('focusin', event)"
      @focusout="(event) => emit('focusout', event)"
      @blur="(event) => emit('blur', event)" />

    <div v-if="props.type !== 'password' && props.icon" class="input-icon">
      <DrIcon :icon="props.icon" @click="iconClick" />
    </div>
    <div v-else-if="props.type === 'password'" class="input-icon">
      <DrIcon :icon="showInput ? 'fa fa-eye-slash' : 'fa fa-eye'" @click="toggleInput" />
    </div>

    <p v-if="props.error" class="input-error">
      {{ props.error }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"

import DrIcon from "@/src/ui/dr-icon.vue"

type Props = {
  id?: string
  value?: number | string
  name?: string
  disabled?: boolean
  placeholder?: string
  icon?: string
  error?: string
  type?: "string" | "password" | "num"
  min?: number
  max?: number
}

const props = withDefaults(defineProps<Props>(), {
  value: "",
  name: "",
  disabled: false,
  placeholder: "",
  icon: "",
  type: "string",
  error: "",
  min: undefined,
  max: undefined,
  id: undefined
})

const emit = defineEmits([
  "input",
  "icon",
  "change",
  "focus",
  "focusin",
  "focusout",
  "blur",
  "keyup",
  "keyupenter"
])

const input = computed({
  get() {
    return props.value
  },
  set(newValue) {
    if (!props.disabled) {
      emit("input", `${newValue}`)
    }
  }
})

const showInput = ref<boolean>(props.type !== "password")

const inputClasses = computed(() => {
  const classes = ["input"]
  if (props.disabled) {
    classes.push("input--disabled")
  }
  if (props.error) {
    classes.push("is-invalid")
  }
  if (props.icon) {
    classes.push("input--icon")
  }

  return classes
})

const displayInputValue = computed(() => {
  const hidePassword = props.type === "password" && !showInput.value
  return hidePassword ? "password" : "text"
})

const iconClick = () => {
  emit("icon")
}

const onEnter = (event) => {
  emit("keyupenter", event)
}

const toggleInput = () => {
  showInput.value = !showInput.value
}

watch(
  () => props.type,
  (newType) => {
    showInput.value = newType !== "password"
  }
)
</script>
