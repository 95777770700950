<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7323 2.00197C9.80835 2.05953 8.01621 2.75085 6.56225 3.87029L9.69393 7.00197H15.0023C15.5523 7.00197 16.0023 7.45197 16.0023 8.00197C16.0023 8.55197 15.5523 9.00197 15.0023 9.00197H11.6939L13.2678 10.5758C13.3522 10.6223 13.4313 10.681 13.5023 10.752C13.5733 10.823 13.632 10.9021 13.6784 10.9865L19.1469 16.455C20.31 14.9413 21.0023 13.0503 21.0023 11.002C21.0023 5.95197 16.8223 1.85197 11.7323 2.00197Z"
      fill="currentColor" />
    <path
      d="M3.00231 10.712C3.05323 9.12341 3.53621 7.62586 4.3351 6.3351L10.3866 12.3866C10.0742 12.7784 10.0994 13.3491 10.4623 13.712L11.0023 14.252L10.5023 14.752C10.0923 15.162 10.0923 15.842 10.5023 16.252C10.9123 16.662 11.5923 16.662 12.0023 16.252L13.1271 15.1271L16.6749 18.6749C16.15 18.995 15.5901 19.2631 15.0023 19.472V21.002C15.0023 21.552 14.5523 22.002 14.0023 22.002C13.4523 22.002 13.0023 21.552 13.0023 21.002V19.942C12.6723 19.982 12.3423 20.002 12.0023 20.002C11.6623 20.002 11.3323 19.982 11.0023 19.942V21.002C11.0023 21.552 10.5523 22.002 10.0023 22.002C9.45231 22.002 9.00231 21.552 9.00231 21.002V19.482C5.42231 18.212 2.87231 14.752 3.00231 10.712Z"
      fill="currentColor" />
    <path
      d="M3.68623 3.31377C3.30724 3.69276 3.30724 4.30724 3.68623 4.68623L19.2552 20.2552C19.6342 20.6342 20.2487 20.6342 20.6277 20.2552C21.0067 19.8762 21.0067 19.2617 20.6277 18.8827L5.0587 3.31377C4.6797 2.93477 4.06523 2.93477 3.68623 3.31377Z"
      fill="currentColor" />
  </svg>
</template>
