<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4975 4.90501C21.0075 4.24501 20.5375 3.29501 19.7075 3.29501H7.53751L15.5075 11.265L20.4975 4.90501Z"
      fill="currentColor" />
    <path
      d="M21.1975 19.785L4.21751 2.80501C3.82751 2.41501 3.19751 2.41501 2.80751 2.80501C2.41751 3.19501 2.41751 3.82501 2.80751 4.21501L10.7075 12.295V17.295C10.7075 18.395 11.6075 19.295 12.7075 19.295C13.8075 19.295 14.7075 18.395 14.7075 17.295V16.125L19.7775 21.195C20.1675 21.585 20.7975 21.585 21.1875 21.195C21.5775 20.805 21.5875 20.175 21.1975 19.785Z"
      fill="currentColor" />
  </svg>
</template>
