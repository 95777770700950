<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="13.5" r="3" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9466 17.876C11.9695 17.4018 10.5 15.6225 10.5 13.5C10.5 11.0147 12.5147 9 15 9C16.0614 9 17.037 9.36749 17.8065 9.98218L12.7766 2.80366C12.3766 2.23366 11.5366 2.23366 11.1366 2.80366L6.09659 10.0537C5.63659 10.7137 6.11659 11.6237 6.92659 11.6237H6.96659L4.06659 16.0837C3.62659 16.7437 4.10659 17.6237 4.90659 17.6237H9.98659V19.6437C9.98659 20.7337 10.8766 21.6237 11.9666 21.6237C13.0566 21.6237 13.9466 20.7337 13.9466 19.6437V17.876Z"
      fill="currentColor" />
  </svg>
</template>
