export const appConfig = {
  appEnv: __APP_ENV__,
  appRelease: __APP_RELEASE__,
  mapBoxToken: __MAPBOX_TOKEN__,
  leafletUrl: {
    default: `https://api.mapbox.com/styles/v1/mcauledeepki/ciu41bi4m00mz2iqile2768cw/tiles/256/{z}/{x}/{y}?access_token=${__MAPBOX_TOKEN__}`,
    fr: `https://api.mapbox.com/styles/v1/mcauledeepki/ciu41t40300nm2iol7z4xhzna/tiles/256/{z}/{x}/{y}?access_token=${__MAPBOX_TOKEN__}`,
    es: `https://api.mapbox.com/styles/v1/mcauledeepki/ciu473ibp00p32iqgzw505p6f/tiles/256/{z}/{x}/{y}?access_token=${__MAPBOX_TOKEN__}`,
    de: `https://api.mapbox.com/styles/v1/mcauledeepki/ciu46t7jo00o02iqi5g8o8x0w/tiles/256/{z}/{x}/{y}?access_token=${__MAPBOX_TOKEN__}`
  }
}
