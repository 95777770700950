<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7608 8.50075H10.7608L11.8576 10.4125C11.8744 10.4416 11.8895 10.4711 11.9032 10.5007H11.9032C11.8896 10.4711 11.8744 10.4416 11.8577 10.4125L10.7608 8.50075ZM11.8402 11.5007H11.8402C11.66 11.7935 11.3406 12 10.9501 12H3.04989C2.92367 12 2.80488 11.9784 2.69582 11.9393C2.80489 11.9784 2.92369 12 3.04992 12H10.9501C11.3406 12 11.66 11.7936 11.8402 11.5007ZM12.9216 11.5007H18.3978C20.0678 11.5007 21.5878 12.6307 21.9278 14.2707C22.2778 15.9907 21.3578 17.5607 19.9278 18.2007C19.2578 18.5007 18.4978 18.0207 18.4978 17.2907C18.4978 16.9007 18.7078 16.5407 19.0678 16.3907C19.6178 16.1607 19.9978 15.6307 19.9978 15.0007C19.9978 14.1707 19.3278 13.5007 18.4978 13.5007H2.9978C2.52597 13.5007 2.12774 13.1696 2.02407 12.7282C2.31899 12.8996 2.6661 13 3.04992 13H10.9501C11.94 13 12.6858 12.3321 12.9216 11.5007ZM12.9527 10.5007C12.9089 10.3019 12.834 10.1047 12.725 9.91472L11.9137 8.50075H15.4978C16.3278 8.50075 16.9978 7.83075 16.9978 7.00075C16.9978 6.17075 16.3278 5.50075 15.4978 5.50075C14.8678 5.50075 14.3378 5.88075 14.1078 6.43075C13.9578 6.78075 13.5978 7.00075 13.2078 7.00075C12.4778 7.00075 11.9978 6.24075 12.3078 5.57075C12.9578 4.12075 14.5678 3.19075 16.3278 3.59075C17.5878 3.88075 18.6178 4.91075 18.9078 6.17075C19.4278 8.46075 17.6978 10.5007 15.4978 10.5007H12.9527ZM7.52458 9.35413H6.47545V10.4125H6.47542V9.35411H7.52458V9.35413ZM14.3478 18.4507C14.0678 19.3407 13.3378 20.0707 12.4478 20.3507C10.9378 20.8307 9.5078 20.1207 8.8578 18.9307C8.5078 18.2807 9.0278 17.5007 9.7678 17.5007H9.7778C10.1178 17.5007 10.4578 17.6607 10.6178 17.9607C10.7878 18.2807 11.1178 18.5007 11.5078 18.5007C12.0578 18.5007 12.5078 18.0507 12.5078 17.5007C12.5078 16.9507 12.0578 16.5007 11.5078 16.5007H2.9978C2.4478 16.5007 1.9978 16.0507 1.9978 15.5007C1.9978 14.9507 2.4478 14.5007 2.9978 14.5007H11.4978C13.4578 14.5007 14.9978 16.4007 14.3478 18.4507Z"
      fill="currentColor" />
    <path
      d="M3.04989 12H10.9501C11.758 12 12.2616 11.1163 11.8576 10.4125L7.90753 3.52786C7.5036 2.82405 6.4964 2.82405 6.09247 3.52786L2.14236 10.4125C1.73843 11.1163 2.24203 12 3.04989 12ZM7 8.29575C6.71148 8.29575 6.47542 8.05762 6.47542 7.76657V6.70822C6.47542 6.41717 6.71148 6.17904 7 6.17904C7.28852 6.17904 7.52458 6.41717 7.52458 6.70822V7.76657C7.52458 8.05762 7.28852 8.29575 7 8.29575ZM7.52458 10.4125H6.47542V9.35411H7.52458V10.4125Z"
      fill="currentColor" />
  </svg>
</template>
