import analytics from "./analytics"
import flags from "./flags"
import vueAxios from "./vue_axios"
import vueBaseConfig from "./vue_base_config"
import modalPlugin from "./vue_modal"
import vueToasts from "./vue_toasts"

import type { App } from "vue"

export const initPlugins = async (app: App) => {
  app.use(vueAxios)
  app.use(vueBaseConfig)
  app.use(analytics)
  app.use(vueToasts)
  app.use(flags)
  app.use(modalPlugin)
}
