/*
utils function to query config document
*/

import type { Permission } from "../typings/globals"

export default {
  getProjectConfigVar: function (df, varId) {
    const dfs = df.split("+")
    for (let i = 0; i < dfs.length; i++) {
      const dfname = dfs[i]
      const v = this.getColumnConfig(dfname, varId)
      if (v) {
        return v
      }
    }
    return null
  },
  getColumnConfig(df, column) {
    const columnPath = column.split(".")
    let col = columnPath.shift()
    let conf = globalThis.project_config.df[df].columns.find((c) => c.id == col)
    while (columnPath.length > 0) {
      if (!conf) {
        return null
      }
      col = columnPath.shift()
      conf = (conf.columns || conf.items || []).find((c) => c.id == col)
    }
    if (!conf) {
      return null
    }
    return conf
  },

  userHasAccess(accessObject, permission?: Permission) {
    const userRole = globalThis.user_config.role_id || ""

    if (permission && userRole.length > 0) {
      const userPermissions = globalThis.user_config.permissions || []

      return userPermissions.includes(permission)
    } else if (userRole.length > 0) {
      return false
    }

    if (accessObject === undefined) {
      return false
    }
    if (accessObject === "*") {
      return true
    }
    const userGroups = globalThis.user_config.groups || []

    if (Object.prototype.hasOwnProperty.call(accessObject, "groups")) {
      accessObject = accessObject.groups
    }

    for (const g of accessObject) {
      if (g === "*" || userGroups.indexOf(g) !== -1) {
        return true
      }
    }

    return false
  },

  userHasScope() {
    return globalThis.user_config.scope !== "*"
  },

  /*
  based of nomenclature, we try to guess if the name of this kpi is related to a fluid
  used to choose an appropriate color for this kpi
  */
  guessFluidForKpiName(kpiName) {
    const fluids = globalThis.project_config.collect.fluids
    if (fluids) {
      const f = fluids.find((f) => kpiName.indexOf(`_${f.id}`) != -1)
      return f ? f.id : null
    }
    return null
  }
}
