<template>
  <component :is="icons[props.icon]" />
</template>

<script setup lang="ts">
import VoltaIcon15minuteCity from "./_private/volta-icon-15minute-city.vue"
import VoltaIconActionCenter from "./_private/volta-icon-action-center.vue"
import VoltaIconAddTask from "./_private/volta-icon-add-task.vue"
import VoltaIconAdd from "./_private/volta-icon-add.vue"
import VoltaIconAdminPanelSettings from "./_private/volta-icon-admin-panel-settings.vue"
import VoltaIconAirQualityWarning from "./_private/volta-icon-air-quality-warning.vue"
import VoltaIconAir from "./_private/volta-icon-air.vue"
import VoltaIconAirportShuttle from "./_private/volta-icon-airport-shuttle.vue"
import VoltaIconAlignHorizontalLeft from "./_private/volta-icon-align-horizontal-left.vue"
import VoltaIconApac from "./_private/volta-icon-apac.vue"
import VoltaIconApartment from "./_private/volta-icon-apartment.vue"
import VoltaIconApps from "./_private/volta-icon-apps.vue"
import VoltaIconAreaChart from "./_private/volta-icon-area-chart.vue"
import VoltaIconArrowBack from "./_private/volta-icon-arrow-back.vue"
import VoltaIconArrowDownLeft from "./_private/volta-icon-arrow-down-left.vue"
import VoltaIconArrowDownRight from "./_private/volta-icon-arrow-down-right.vue"
import VoltaIconArrowDown from "./_private/volta-icon-arrow-down.vue"
import VoltaIconArrowDropDown from "./_private/volta-icon-arrow-drop-down.vue"
import VoltaIconArrowDropLeft from "./_private/volta-icon-arrow-drop-left.vue"
import VoltaIconArrowDropRight from "./_private/volta-icon-arrow-drop-right.vue"
import VoltaIconArrowDropUp from "./_private/volta-icon-arrow-drop-up.vue"
import VoltaIconArrowForward from "./_private/volta-icon-arrow-forward.vue"
import VoltaIconArrowLeft from "./_private/volta-icon-arrow-left.vue"
import VoltaIconArrowRight from "./_private/volta-icon-arrow-right.vue"
import VoltaIconArrowUpLeft from "./_private/volta-icon-arrow-up-left.vue"
import VoltaIconArrowUpRight from "./_private/volta-icon-arrow-up-right.vue"
import VoltaIconArrowUp from "./_private/volta-icon-arrow-up.vue"
import VoltaIconAttachFile from "./_private/volta-icon-attach-file.vue"
import VoltaIconBallot from "./_private/volta-icon-ballot.vue"
import VoltaIconBank from "./_private/volta-icon-bank.vue"
import VoltaIconBarchart from "./_private/volta-icon-barchart.vue"
import VoltaIconBatteryEmpty from "./_private/volta-icon-battery-empty.vue"
import VoltaIconBatteryFull from "./_private/volta-icon-battery-full.vue"
import VoltaIconBatteryHalf from "./_private/volta-icon-battery-half.vue"
import VoltaIconBatteryQuarter from "./_private/volta-icon-battery-quarter.vue"
import VoltaIconBed from "./_private/volta-icon-bed.vue"
import VoltaIconBiomass from "./_private/volta-icon-biomass.vue"
import VoltaIconBuildingParameters from "./_private/volta-icon-building-parameters.vue"
import VoltaIconBusiness from "./_private/volta-icon-business.vue"
import VoltaIconCalculate from "./_private/volta-icon-calculate.vue"
import VoltaIconCalendarCheck from "./_private/volta-icon-calendar-check.vue"
import VoltaIconCalendarMonth from "./_private/volta-icon-calendar-month.vue"
import VoltaIconCalendarToday from "./_private/volta-icon-calendar-today.vue"
import VoltaIconCamera from "./_private/volta-icon-camera.vue"
import VoltaIconCancel from "./_private/volta-icon-cancel.vue"
import VoltaIconCategory from "./_private/volta-icon-category.vue"
import VoltaIconCertificate from "./_private/volta-icon-certificate.vue"
import VoltaIconChampion from "./_private/volta-icon-champion.vue"
import VoltaIconChart from "./_private/volta-icon-chart.vue"
import VoltaIconCheckAnimated from "./_private/volta-icon-check-animated.vue"
import VoltaIconCheckCircle from "./_private/volta-icon-check-circle.vue"
import VoltaIconCheck from "./_private/volta-icon-check.vue"
import VoltaIconChevronLeft from "./_private/volta-icon-chevron-left.vue"
import VoltaIconChevronRight from "./_private/volta-icon-chevron-right.vue"
import VoltaIconCircleArrowRight from "./_private/volta-icon-circle-arrow-right.vue"
import VoltaIconCircleDropDown from "./_private/volta-icon-circle-drop-down.vue"
import VoltaIconCircleExpandMore from "./_private/volta-icon-circle-expand-more.vue"
import VoltaIconCity from "./_private/volta-icon-city.vue"
import VoltaIconClose from "./_private/volta-icon-close.vue"
import VoltaIconCloudDownload from "./_private/volta-icon-cloud-download.vue"
import VoltaIconCloudUpload from "./_private/volta-icon-cloud-upload.vue"
import VoltaIconCloud from "./_private/volta-icon-cloud.vue"
import VoltaIconComment from "./_private/volta-icon-comment.vue"
import VoltaIconCommute from "./_private/volta-icon-commute.vue"
import VoltaIconComputer from "./_private/volta-icon-computer.vue"
import VoltaIconContactCalendar from "./_private/volta-icon-contact-calendar.vue"
import VoltaIconContentCopy from "./_private/volta-icon-content-copy.vue"
import VoltaIconContentPasteSearch from "./_private/volta-icon-content-paste-search.vue"
import VoltaIconCost from "./_private/volta-icon-cost.vue"
import VoltaIconCustomisation from "./_private/volta-icon-customisation.vue"
import VoltaIconDashboard from "./_private/volta-icon-dashboard.vue"
import VoltaIconDataExploration from "./_private/volta-icon-data-exploration.vue"
import VoltaIconDatabase from "./_private/volta-icon-database.vue"
import VoltaIconDeepkiLogo from "./_private/volta-icon-deepki-logo.vue"
import VoltaIconDelete from "./_private/volta-icon-delete.vue"
import VoltaIconDeliveryDining from "./_private/volta-icon-delivery-dining.vue"
import VoltaIconDescription from "./_private/volta-icon-description.vue"
import VoltaIconDirectionsBike from "./_private/volta-icon-directions-bike.vue"
import VoltaIconDirectionsBoat from "./_private/volta-icon-directions-boat.vue"
import VoltaIconDirectionsBus from "./_private/volta-icon-directions-bus.vue"
import VoltaIconDirectionsCar from "./_private/volta-icon-directions-car.vue"
import VoltaIconDirectionsRailway from "./_private/volta-icon-directions-railway.vue"
import VoltaIconDirectionsSubway from "./_private/volta-icon-directions-subway.vue"
import VoltaIconDirectionsWalk from "./_private/volta-icon-directions-walk.vue"
import VoltaIconDistrictCooling from "./_private/volta-icon-district-cooling.vue"
import VoltaIconDistrictHeating from "./_private/volta-icon-district-heating.vue"
import VoltaIconDoNotDisturb from "./_private/volta-icon-do-not-disturb.vue"
import VoltaIconDollar from "./_private/volta-icon-dollar.vue"
import VoltaIconDonut from "./_private/volta-icon-donut.vue"
import VoltaIconDoubleArrowDown from "./_private/volta-icon-double-arrow-down.vue"
import VoltaIconDoubleArrowLeft from "./_private/volta-icon-double-arrow-left.vue"
import VoltaIconDoubleArrowRight from "./_private/volta-icon-double-arrow-right.vue"
import VoltaIconDownload from "./_private/volta-icon-download.vue"
import VoltaIconDragIndicator from "./_private/volta-icon-drag-indicator.vue"
import VoltaIconEditNote from "./_private/volta-icon-edit-note.vue"
import VoltaIconEdit from "./_private/volta-icon-edit.vue"
import VoltaIconElectricBike from "./_private/volta-icon-electric-bike.vue"
import VoltaIconElectricCar from "./_private/volta-icon-electric-car.vue"
import VoltaIconElectricMeter from "./_private/volta-icon-electric-meter.vue"
import VoltaIconElectricMoped from "./_private/volta-icon-electric-moped.vue"
import VoltaIconElectricRickshaw from "./_private/volta-icon-electric-rickshaw.vue"
import VoltaIconElectricScooter from "./_private/volta-icon-electric-scooter.vue"
import VoltaIconElectricalServices from "./_private/volta-icon-electrical-services.vue"
import VoltaIconElectricity from "./_private/volta-icon-electricity.vue"
import VoltaIconEmea from "./_private/volta-icon-emea.vue"
import VoltaIconEmpty from "./_private/volta-icon-empty.vue"
import VoltaIconEnergySavings from "./_private/volta-icon-energy-savings.vue"
import VoltaIconError from "./_private/volta-icon-error.vue"
import VoltaIconEuro from "./_private/volta-icon-euro.vue"
import VoltaIconEvStation from "./_private/volta-icon-ev-station.vue"
import VoltaIconExitToApp from "./_private/volta-icon-exit-to-app.vue"
import VoltaIconExpandLess from "./_private/volta-icon-expand-less.vue"
import VoltaIconExpandMore from "./_private/volta-icon-expand-more.vue"
import VoltaIconExport from "./_private/volta-icon-export.vue"
import VoltaIconExtension from "./_private/volta-icon-extension.vue"
import VoltaIconFavoriteEmpty from "./_private/volta-icon-favorite-empty.vue"
import VoltaIconFavorite from "./_private/volta-icon-favorite.vue"
import VoltaIconFileUpload from "./_private/volta-icon-file-upload.vue"
import VoltaIconFilterOff from "./_private/volta-icon-filter-off.vue"
import VoltaIconFilter from "./_private/volta-icon-filter.vue"
import VoltaIconFire from "./_private/volta-icon-fire.vue"
import VoltaIconFlag from "./_private/volta-icon-flag.vue"
import VoltaIconFlight from "./_private/volta-icon-flight.vue"
import VoltaIconFlowTree from "./_private/volta-icon-flow-tree.vue"
import VoltaIconFocus from "./_private/volta-icon-focus.vue"
import VoltaIconFolderCopy from "./_private/volta-icon-folder-copy.vue"
import VoltaIconFolderOpen from "./_private/volta-icon-folder-open.vue"
import VoltaIconFolder from "./_private/volta-icon-folder.vue"
import VoltaIconForum from "./_private/volta-icon-forum.vue"
import VoltaIconFuel from "./_private/volta-icon-fuel.vue"
import VoltaIconFullscreenExit from "./_private/volta-icon-fullscreen-exit.vue"
import VoltaIconFullscreen from "./_private/volta-icon-fullscreen.vue"
import VoltaIconGasMeter from "./_private/volta-icon-gas-meter.vue"
import VoltaIconGas from "./_private/volta-icon-gas.vue"
import VoltaIconGroupAdd from "./_private/volta-icon-group-add.vue"
import VoltaIconGroups from "./_private/volta-icon-groups.vue"
import VoltaIconHandshake from "./_private/volta-icon-handshake.vue"
import VoltaIconHazardousWaste from "./_private/volta-icon-hazardous-waste.vue"
import VoltaIconHeatPump from "./_private/volta-icon-heat-pump.vue"
import VoltaIconHeater from "./_private/volta-icon-heater.vue"
import VoltaIconHeight from "./_private/volta-icon-height.vue"
import VoltaIconHelpCenter from "./_private/volta-icon-help-center.vue"
import VoltaIconHelp from "./_private/volta-icon-help.vue"
import VoltaIconHistory from "./_private/volta-icon-history.vue"
import VoltaIconHome from "./_private/volta-icon-home.vue"
import VoltaIconHourglassFull from "./_private/volta-icon-hourglass-full.vue"
import VoltaIconHourglassTop from "./_private/volta-icon-hourglass-top.vue"
import VoltaIconHourglass from "./_private/volta-icon-hourglass.vue"
import VoltaIconHouseDamage from "./_private/volta-icon-house-damage.vue"
import VoltaIconHttps from "./_private/volta-icon-https.vue"
import VoltaIconHumidity from "./_private/volta-icon-humidity.vue"
import VoltaIconHvac from "./_private/volta-icon-hvac.vue"
import VoltaIconImage from "./_private/volta-icon-image.vue"
import VoltaIconInfo from "./_private/volta-icon-info.vue"
import VoltaIconInsights from "./_private/volta-icon-insights.vue"
import VoltaIconInvoices from "./_private/volta-icon-invoices.vue"
import VoltaIconKey from "./_private/volta-icon-key.vue"
import VoltaIconLandlord from "./_private/volta-icon-landlord.vue"
import VoltaIconLanguage from "./_private/volta-icon-language.vue"
import VoltaIconLatam from "./_private/volta-icon-latam.vue"
import VoltaIconLightbulb from "./_private/volta-icon-lightbulb.vue"
import VoltaIconLineAxis from "./_private/volta-icon-line-axis.vue"
import VoltaIconLinearScale from "./_private/volta-icon-linear-scale.vue"
import VoltaIconLinechart from "./_private/volta-icon-linechart.vue"
import VoltaIconLinkOff from "./_private/volta-icon-link-off.vue"
import VoltaIconLink from "./_private/volta-icon-link.vue"
import VoltaIconList from "./_private/volta-icon-list.vue"
import VoltaIconLoading from "./_private/volta-icon-loading.vue"
import VoltaIconLocalHospital from "./_private/volta-icon-local-hospital.vue"
import VoltaIconLocalShipping from "./_private/volta-icon-local-shipping.vue"
import VoltaIconLocalTaxi from "./_private/volta-icon-local-taxi.vue"
import VoltaIconLocationNotListed from "./_private/volta-icon-location-not-listed.vue"
import VoltaIconLocation from "./_private/volta-icon-location.vue"
import VoltaIconLockOpen from "./_private/volta-icon-lock-open.vue"
import VoltaIconLock from "./_private/volta-icon-lock.vue"
import VoltaIconLogin from "./_private/volta-icon-login.vue"
import VoltaIconMailOpen from "./_private/volta-icon-mail-open.vue"
import VoltaIconMail from "./_private/volta-icon-mail.vue"
import VoltaIconManageAccounts from "./_private/volta-icon-manage-accounts.vue"
import VoltaIconManualRecord from "./_private/volta-icon-manual-record.vue"
import VoltaIconMap from "./_private/volta-icon-map.vue"
import VoltaIconMarkChatUnread from "./_private/volta-icon-mark-chat-unread.vue"
import VoltaIconMedian from "./_private/volta-icon-median.vue"
import VoltaIconMenuBook from "./_private/volta-icon-menu-book.vue"
import VoltaIconMenu from "./_private/volta-icon-menu.vue"
import VoltaIconMoney from "./_private/volta-icon-money.vue"
import VoltaIconMoped from "./_private/volta-icon-moped.vue"
import VoltaIconMoreHoriz from "./_private/volta-icon-more-horiz.vue"
import VoltaIconMoreVert from "./_private/volta-icon-more-vert.vue"
import VoltaIconNewReleases from "./_private/volta-icon-new-releases.vue"
import VoltaIconNoDocument from "./_private/volta-icon-no-document.vue"
import VoltaIconNoMeter from "./_private/volta-icon-no-meter.vue"
import VoltaIconNotSent from "./_private/volta-icon-not-sent.vue"
import VoltaIconNoteAdd from "./_private/volta-icon-note-add.vue"
import VoltaIconNotificationsActive from "./_private/volta-icon-notifications-active.vue"
import VoltaIconNotifications from "./_private/volta-icon-notifications.vue"
import VoltaIconOther from "./_private/volta-icon-other.vue"
import VoltaIconOverconsumptionMonthly from "./_private/volta-icon-overconsumption-monthly.vue"
import VoltaIconOverconsumption from "./_private/volta-icon-overconsumption.vue"
import VoltaIconOverload from "./_private/volta-icon-overload.vue"
import VoltaIconPdf from "./_private/volta-icon-pdf.vue"
import VoltaIconPedalBike from "./_private/volta-icon-pedal-bike.vue"
import VoltaIconPellets from "./_private/volta-icon-pellets.vue"
import VoltaIconPeople from "./_private/volta-icon-people.vue"
import VoltaIconPercent from "./_private/volta-icon-percent.vue"
import VoltaIconPersonAdd from "./_private/volta-icon-person-add.vue"
import VoltaIconPerson from "./_private/volta-icon-person.vue"
import VoltaIconPhone from "./_private/volta-icon-phone.vue"
import VoltaIconPhotovoltaic from "./_private/volta-icon-photovoltaic.vue"
import VoltaIconPriorityHigh from "./_private/volta-icon-priority-high.vue"
import VoltaIconPriorityLow from "./_private/volta-icon-priority-low.vue"
import VoltaIconPropane from "./_private/volta-icon-propane.vue"
import VoltaIconPublic from "./_private/volta-icon-public.vue"
import VoltaIconPushPinRotation from "./_private/volta-icon-push-pin-rotation.vue"
import VoltaIconPushPin from "./_private/volta-icon-push-pin.vue"
import VoltaIconRadar from "./_private/volta-icon-radar.vue"
import VoltaIconRecycling from "./_private/volta-icon-recycling.vue"
import VoltaIconRefresh from "./_private/volta-icon-refresh.vue"
import VoltaIconRefrigerant from "./_private/volta-icon-refrigerant.vue"
import VoltaIconRelatedObject from "./_private/volta-icon-related-object.vue"
import VoltaIconRemove from "./_private/volta-icon-remove.vue"
import VoltaIconRenewable from "./_private/volta-icon-renewable.vue"
import VoltaIconReportingCenter from "./_private/volta-icon-reporting-center.vue"
import VoltaIconSave from "./_private/volta-icon-save.vue"
import VoltaIconSavings from "./_private/volta-icon-savings.vue"
import VoltaIconScatterPlot from "./_private/volta-icon-scatter-plot.vue"
import VoltaIconSearchOff from "./_private/volta-icon-search-off.vue"
import VoltaIconSearch from "./_private/volta-icon-search.vue"
import VoltaIconSecurity from "./_private/volta-icon-security.vue"
import VoltaIconSend from "./_private/volta-icon-send.vue"
import VoltaIconSettings from "./_private/volta-icon-settings.vue"
import VoltaIconSortByAlpha from "./_private/volta-icon-sort-by-alpha.vue"
import VoltaIconSpeed from "./_private/volta-icon-speed.vue"
import VoltaIconStar from "./_private/volta-icon-star.vue"
import VoltaIconStop from "./_private/volta-icon-stop.vue"
import VoltaIconStreetview from "./_private/volta-icon-streetview.vue"
import VoltaIconSubway from "./_private/volta-icon-subway.vue"
import VoltaIconSupervisorAccount from "./_private/volta-icon-supervisor-account.vue"
import VoltaIconSurface from "./_private/volta-icon-surface.vue"
import VoltaIconSync from "./_private/volta-icon-sync.vue"
import VoltaIconTableView from "./_private/volta-icon-table-view.vue"
import VoltaIconTable from "./_private/volta-icon-table.vue"
import VoltaIconTaskManagementCenter from "./_private/volta-icon-task-management-center.vue"
import VoltaIconTenant from "./_private/volta-icon-tenant.vue"
import VoltaIconThumbDown from "./_private/volta-icon-thumb-down.vue"
import VoltaIconThumbUp from "./_private/volta-icon-thumb-up.vue"
import VoltaIconTimelapse from "./_private/volta-icon-timelapse.vue"
import VoltaIconTools from "./_private/volta-icon-tools.vue"
import VoltaIconTrain from "./_private/volta-icon-train.vue"
import VoltaIconTram from "./_private/volta-icon-tram.vue"
import VoltaIconTranslate from "./_private/volta-icon-translate.vue"
import VoltaIconTrendingDown from "./_private/volta-icon-trending-down.vue"
import VoltaIconTrendingFlat from "./_private/volta-icon-trending-flat.vue"
import VoltaIconTrendingUp from "./_private/volta-icon-trending-up.vue"
import VoltaIconTwoWheeler from "./_private/volta-icon-two-wheeler.vue"
import VoltaIconUnfoldMore from "./_private/volta-icon-unfold-more.vue"
import VoltaIconUnlock from "./_private/volta-icon-unlock.vue"
import VoltaIconUpdate from "./_private/volta-icon-update.vue"
import VoltaIconUserSettings from "./_private/volta-icon-user-settings.vue"
import VoltaIconVerifiedUser from "./_private/volta-icon-verified-user.vue"
import VoltaIconVerified from "./_private/volta-icon-verified.vue"
import VoltaIconViewColumn from "./_private/volta-icon-view-column.vue"
import VoltaIconVisibilityOff from "./_private/volta-icon-visibility-off.vue"
import VoltaIconVisibility from "./_private/volta-icon-visibility.vue"
import VoltaIconWarning from "./_private/volta-icon-warning.vue"
import VoltaIconWaste from "./_private/volta-icon-waste.vue"
import VoltaIconWatch from "./_private/volta-icon-watch.vue"
import VoltaIconWaterDamage from "./_private/volta-icon-water-damage.vue"
import VoltaIconWater from "./_private/volta-icon-water.vue"
import VoltaIconWifiOff from "./_private/volta-icon-wifi-off.vue"
import VoltaIconWifiTethering from "./_private/volta-icon-wifi-tethering.vue"
import VoltaIconWifi from "./_private/volta-icon-wifi.vue"
import VoltaIconWindPower from "./_private/volta-icon-wind-power.vue"
import VoltaIconWoodChips from "./_private/volta-icon-wood-chips.vue"
import VoltaIconWorkcase from "./_private/volta-icon-workcase.vue"

import type { Icon } from "./volta-icon"
import type { Component } from "vue"

const icons: {
  [componentName in Icon]: Component
} = {
  "15minute-city": VoltaIcon15minuteCity,
  "add-task": VoltaIconAddTask,
  add: VoltaIconAdd,
  "action-center": VoltaIconActionCenter,
  "admin-panel-settings": VoltaIconAdminPanelSettings,
  "air-quality-warning": VoltaIconAirQualityWarning,
  air: VoltaIconAir,
  "airport-shuttle": VoltaIconAirportShuttle,
  "align-horizontal-left": VoltaIconAlignHorizontalLeft,
  apac: VoltaIconApac,
  apartment: VoltaIconApartment,
  apps: VoltaIconApps,
  "area-chart": VoltaIconAreaChart,
  "arrow-back": VoltaIconArrowBack,
  "arrow-down-left": VoltaIconArrowDownLeft,
  "arrow-down-right": VoltaIconArrowDownRight,
  "arrow-down": VoltaIconArrowDown,
  "arrow-drop-down": VoltaIconArrowDropDown,
  "arrow-drop-left": VoltaIconArrowDropLeft,
  "arrow-drop-right": VoltaIconArrowDropRight,
  "arrow-drop-up": VoltaIconArrowDropUp,
  "arrow-forward": VoltaIconArrowForward,
  "arrow-left": VoltaIconArrowLeft,
  "arrow-right": VoltaIconArrowRight,
  "arrow-up-left": VoltaIconArrowUpLeft,
  "arrow-up-right": VoltaIconArrowUpRight,
  "arrow-up": VoltaIconArrowUp,
  "attach-file": VoltaIconAttachFile,
  ballot: VoltaIconBallot,
  bank: VoltaIconBank,
  barchart: VoltaIconBarchart,
  "battery-empty": VoltaIconBatteryEmpty,
  "battery-full": VoltaIconBatteryFull,
  "battery-half": VoltaIconBatteryHalf,
  "battery-quarter": VoltaIconBatteryQuarter,
  bed: VoltaIconBed,
  biomass: VoltaIconBiomass,
  "building-parameters": VoltaIconBuildingParameters,
  business: VoltaIconBusiness,
  calculate: VoltaIconCalculate,
  "calendar-check": VoltaIconCalendarCheck,
  "calendar-month": VoltaIconCalendarMonth,
  "calendar-today": VoltaIconCalendarToday,
  camera: VoltaIconCamera,
  cancel: VoltaIconCancel,
  category: VoltaIconCategory,
  certificate: VoltaIconCertificate,
  champion: VoltaIconChampion,
  chart: VoltaIconChart,
  "check-animated": VoltaIconCheckAnimated,
  "check-circle": VoltaIconCheckCircle,
  check: VoltaIconCheck,
  "chevron-left": VoltaIconChevronLeft,
  "chevron-right": VoltaIconChevronRight,
  "circle-arrow-right": VoltaIconCircleArrowRight,
  "circle-drop-down": VoltaIconCircleDropDown,
  "circle-expand-more": VoltaIconCircleExpandMore,
  city: VoltaIconCity,
  close: VoltaIconClose,
  "cloud-download": VoltaIconCloudDownload,
  "cloud-upload": VoltaIconCloudUpload,
  cloud: VoltaIconCloud,
  comment: VoltaIconComment,
  commute: VoltaIconCommute,
  computer: VoltaIconComputer,
  "contact-calendar": VoltaIconContactCalendar,
  "content-copy": VoltaIconContentCopy,
  "content-paste-search": VoltaIconContentPasteSearch,
  cost: VoltaIconCost,
  customisation: VoltaIconCustomisation,
  dashboard: VoltaIconDashboard,
  "data-exploration": VoltaIconDataExploration,
  database: VoltaIconDatabase,
  "deepki-logo": VoltaIconDeepkiLogo,
  delete: VoltaIconDelete,
  "delivery-dining": VoltaIconDeliveryDining,
  description: VoltaIconDescription,
  "directions-bike": VoltaIconDirectionsBike,
  "directions-boat": VoltaIconDirectionsBoat,
  "directions-bus": VoltaIconDirectionsBus,
  "directions-car": VoltaIconDirectionsCar,
  "directions-railway": VoltaIconDirectionsRailway,
  "directions-subway": VoltaIconDirectionsSubway,
  "directions-walk": VoltaIconDirectionsWalk,
  "district-cooling": VoltaIconDistrictCooling,
  "district-heating": VoltaIconDistrictHeating,
  "do-not-disturb": VoltaIconDoNotDisturb,
  dollar: VoltaIconDollar,
  donut: VoltaIconDonut,
  "double-arrow-down": VoltaIconDoubleArrowDown,
  "double-arrow-left": VoltaIconDoubleArrowLeft,
  "double-arrow-right": VoltaIconDoubleArrowRight,
  download: VoltaIconDownload,
  "drag-indicator": VoltaIconDragIndicator,
  "edit-note": VoltaIconEditNote,
  edit: VoltaIconEdit,
  "electric-bike": VoltaIconElectricBike,
  "electric-car": VoltaIconElectricCar,
  "electric-meter": VoltaIconElectricMeter,
  "electric-moped": VoltaIconElectricMoped,
  "electric-rickshaw": VoltaIconElectricRickshaw,
  "electric-scooter": VoltaIconElectricScooter,
  "electrical-services": VoltaIconElectricalServices,
  electricity: VoltaIconElectricity,
  "energy-savings": VoltaIconEnergySavings,
  error: VoltaIconError,
  euro: VoltaIconEuro,
  emea: VoltaIconEmea,
  empty: VoltaIconEmpty,
  "ev-station": VoltaIconEvStation,
  "exit-to-app": VoltaIconExitToApp,
  "expand-less": VoltaIconExpandLess,
  "expand-more": VoltaIconExpandMore,
  export: VoltaIconExport,
  extension: VoltaIconExtension,
  favorite: VoltaIconFavorite,
  "favorite-empty": VoltaIconFavoriteEmpty,
  "file-upload": VoltaIconFileUpload,
  "filter-off": VoltaIconFilterOff,
  filter: VoltaIconFilter,
  fire: VoltaIconFire,
  flag: VoltaIconFlag,
  flight: VoltaIconFlight,
  "flow-tree": VoltaIconFlowTree,
  focus: VoltaIconFocus,
  "folder-copy": VoltaIconFolderCopy,
  "folder-open": VoltaIconFolderOpen,
  folder: VoltaIconFolder,
  forum: VoltaIconForum,
  fuel: VoltaIconFuel,
  "fullscreen-exit": VoltaIconFullscreenExit,
  fullscreen: VoltaIconFullscreen,
  "gas-meter": VoltaIconGasMeter,
  gas: VoltaIconGas,
  "group-add": VoltaIconGroupAdd,
  groups: VoltaIconGroups,
  handshake: VoltaIconHandshake,
  "hazardous-waste": VoltaIconHazardousWaste,
  "heat-pump": VoltaIconHeatPump,
  heater: VoltaIconHeater,
  height: VoltaIconHeight,
  "help-center": VoltaIconHelpCenter,
  help: VoltaIconHelp,
  history: VoltaIconHistory,
  home: VoltaIconHome,
  "hourglass-full": VoltaIconHourglassFull,
  "hourglass-top": VoltaIconHourglassTop,
  hourglass: VoltaIconHourglass,
  "house-damage": VoltaIconHouseDamage,
  https: VoltaIconHttps,
  humidity: VoltaIconHumidity,
  hvac: VoltaIconHvac,
  image: VoltaIconImage,
  info: VoltaIconInfo,
  insights: VoltaIconInsights,
  invoices: VoltaIconInvoices,
  key: VoltaIconKey,
  landlord: VoltaIconLandlord,
  language: VoltaIconLanguage,
  latam: VoltaIconLatam,
  lightbulb: VoltaIconLightbulb,
  "line-axis": VoltaIconLineAxis,
  "linear-scale": VoltaIconLinearScale,
  linechart: VoltaIconLinechart,
  "link-off": VoltaIconLinkOff,
  link: VoltaIconLink,
  list: VoltaIconList,
  loading: VoltaIconLoading,
  "local-hospital": VoltaIconLocalHospital,
  "local-shipping": VoltaIconLocalShipping,
  "local-taxi": VoltaIconLocalTaxi,
  "location-not-listed": VoltaIconLocationNotListed,
  location: VoltaIconLocation,
  lock: VoltaIconLock,
  "lock-open": VoltaIconLockOpen,
  login: VoltaIconLogin,
  "mail-open": VoltaIconMailOpen,
  mail: VoltaIconMail,
  "manage-accounts": VoltaIconManageAccounts,
  "manual-record": VoltaIconManualRecord,
  map: VoltaIconMap,
  "mark-chat-unread": VoltaIconMarkChatUnread,
  median: VoltaIconMedian,
  "menu-book": VoltaIconMenuBook,
  menu: VoltaIconMenu,
  money: VoltaIconMoney,
  moped: VoltaIconMoped,
  "more-horiz": VoltaIconMoreHoriz,
  "more-vert": VoltaIconMoreVert,
  "new-releases": VoltaIconNewReleases,
  "no-document": VoltaIconNoDocument,
  "no-meter": VoltaIconNoMeter,
  "not-sent": VoltaIconNotSent,
  "note-add": VoltaIconNoteAdd,
  "notifications-active": VoltaIconNotificationsActive,
  notifications: VoltaIconNotifications,
  other: VoltaIconOther,
  "overconsumption-montly": VoltaIconOverconsumptionMonthly,
  overconsumption: VoltaIconOverconsumption,
  overload: VoltaIconOverload,
  pdf: VoltaIconPdf,
  "pedal-bike": VoltaIconPedalBike,
  pellets: VoltaIconPellets,
  people: VoltaIconPeople,
  percent: VoltaIconPercent,
  "person-add": VoltaIconPersonAdd,
  person: VoltaIconPerson,
  phone: VoltaIconPhone,
  photovoltaic: VoltaIconPhotovoltaic,
  "priority-high": VoltaIconPriorityHigh,
  "priority-low": VoltaIconPriorityLow,
  propane: VoltaIconPropane,
  public: VoltaIconPublic,
  "push-pin": VoltaIconPushPin,
  "push-pin-rotation": VoltaIconPushPinRotation,
  radar: VoltaIconRadar,
  recycling: VoltaIconRecycling,
  refresh: VoltaIconRefresh,
  refrigerant: VoltaIconRefrigerant,
  remove: VoltaIconRemove,
  renewable: VoltaIconRenewable,
  "reporting-center": VoltaIconReportingCenter,
  save: VoltaIconSave,
  savings: VoltaIconSavings,
  "scatter-plot": VoltaIconScatterPlot,
  "search-off": VoltaIconSearchOff,
  search: VoltaIconSearch,
  security: VoltaIconSecurity,
  send: VoltaIconSend,
  settings: VoltaIconSettings,
  "sort-by-alpha": VoltaIconSortByAlpha,
  speed: VoltaIconSpeed,
  star: VoltaIconStar,
  stop: VoltaIconStop,
  streetview: VoltaIconStreetview,
  subway: VoltaIconSubway,
  "supervisor-account": VoltaIconSupervisorAccount,
  surface: VoltaIconSurface,
  sync: VoltaIconSync,
  "task-management-center": VoltaIconTaskManagementCenter,
  "table-view": VoltaIconTableView,
  table: VoltaIconTable,
  tenant: VoltaIconTenant,
  "thumb-down": VoltaIconThumbDown,
  "thumb-up": VoltaIconThumbUp,
  timelapse: VoltaIconTimelapse,
  unlock: VoltaIconUnlock,
  tools: VoltaIconTools,
  train: VoltaIconTrain,
  tram: VoltaIconTram,
  translate: VoltaIconTranslate,
  "trending-down": VoltaIconTrendingDown,
  "trending-flat": VoltaIconTrendingFlat,
  "trending-up": VoltaIconTrendingUp,
  "two-wheeler": VoltaIconTwoWheeler,
  "unfold-more": VoltaIconUnfoldMore,
  update: VoltaIconUpdate,
  "user-settings": VoltaIconUserSettings,
  "related-object": VoltaIconRelatedObject,
  "verified-user": VoltaIconVerifiedUser,
  verified: VoltaIconVerified,
  "view-column": VoltaIconViewColumn,
  "visibility-off": VoltaIconVisibilityOff,
  visibility: VoltaIconVisibility,
  warning: VoltaIconWarning,
  waste: VoltaIconWaste,
  watch: VoltaIconWatch,
  "water-damage": VoltaIconWaterDamage,
  water: VoltaIconWater,
  "wifi-off": VoltaIconWifiOff,
  wifi: VoltaIconWifi,
  "wifi-tethering": VoltaIconWifiTethering,
  "wind-power": VoltaIconWindPower,
  "wood-chips": VoltaIconWoodChips,
  workcase: VoltaIconWorkcase
} as const

const props = defineProps<{
  icon: Icon
}>()
</script>
