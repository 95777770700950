import mitt from "mitt"

export namespace EventHubPlugin {
  const emitter = mitt()

  // listen to an event
  export function $on(event: string, callback) {
    emitter.on(event, callback)
  }

  // fire an event
  export function $emit(event: string, data) {
    emitter.emit(event, data)
  }

  // clearing all events
  export function $clear() {
    emitter.all.clear()
  }
}

const $events = EventHubPlugin
export { $events }
