<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.82116 16H15.0012V15C15.0012 12.79 16.7912 11 19.0012 11H19.7412L17.8412 2.56C17.6312 1.65 16.8212 1 15.8912 1H13.0012C12.4512 1 12.0012 1.45 12.0012 2C12.0012 2.55 12.4512 3 13.0012 3H15.8912L17.2912 9.25H17.2812C15.1212 9.9 13.4712 11.73 13.0912 14H7.82116C7.34116 12.66 5.96116 11.76 4.40116 12.06C3.22116 12.29 2.27116 13.26 2.05116 14.44C1.70116 16.34 3.16116 18 5.00116 18C6.30116 18 7.40116 17.16 7.82116 16ZM5.00116 16C4.45116 16 4.00116 15.55 4.00116 15C4.00116 14.45 4.45116 14 5.00116 14C5.55116 14 6.00116 14.45 6.00116 15C6.00116 15.55 5.55116 16 5.00116 16Z"
      fill="currentColor" />
    <path
      d="M19.0012 12C17.3412 12 16.0012 13.34 16.0012 15C16.0012 16.66 17.3412 18 19.0012 18C20.6612 18 22.0012 16.66 22.0012 15C22.0012 13.34 20.6612 12 19.0012 12ZM19.0012 16C18.4512 16 18.0012 15.55 18.0012 15C18.0012 14.45 18.4512 14 19.0012 14C19.5512 14 20.0012 14.45 20.0012 15C20.0012 15.55 19.5512 16 19.0012 16Z"
      fill="currentColor" />
    <path d="M11.0012 20H7.00116L13.0012 23V21H17.0012L11.0012 18V20Z" fill="currentColor" />
  </svg>
</template>
