<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H12.6663L12.1846 19.191C11.8602 18.6359 11.9718 17.9265 12.4552 17.5014C11.9815 17.0813 11.8534 16.3793 12.1846 15.8125L12.1881 15.8066L12.86 14.6746C13.0157 14.4102 13.2473 14.2186 13.5108 14.1104C12.9583 14.0368 12.4426 14 12 14ZM14.1711 15.036C14.1475 15.026 14.1233 15.0187 14.0989 15.014C14.1233 15.0187 14.1474 15.026 14.1711 15.036H14.1711ZM14.0758 19.9896C14.108 19.9856 14.14 19.9771 14.1711 19.964H14.1711C14.14 19.9771 14.108 19.9856 14.0758 19.9896ZM19.6746 16.904C19.6746 16.904 19.6746 16.904 19.6746 16.904L19.1889 17.262C19.1961 17.3425 19.1997 17.4195 19.1997 17.5C19.1997 17.5805 19.1961 17.6575 19.1853 17.738L19.8548 18.2315C19.9988 18.34 20.0384 18.5325 19.9484 18.6865L19.2753 19.817C19.2234 19.9059 19.1366 19.9644 19.0408 19.9846C19.1367 19.9645 19.2234 19.9059 19.2753 19.817L19.9485 18.6865C20.0384 18.5325 19.9988 18.34 19.8549 18.2315L19.1853 17.738C19.1961 17.6575 19.1997 17.5805 19.1997 17.5C19.1997 17.4195 19.1961 17.3425 19.1889 17.262L19.6746 16.904ZM17.6153 20L17.6303 19.8835C17.7779 19.817 17.9183 19.7365 18.0515 19.6455L18.0514 19.6455C17.9182 19.7365 17.7779 19.817 17.6303 19.8835L17.6152 20H17.6153ZM16.2638 18.7007C15.6888 18.5874 15.2545 18.0921 15.2545 17.5C15.2545 16.9089 15.6872 16.4143 16.2608 16.2999C15.6872 16.4144 15.2546 16.9089 15.2546 17.5C15.2546 18.0921 15.6888 18.5874 16.2638 18.7007Z"
      fill="currentColor" />
    <path
      d="M19.1997 17.5C19.1997 17.4195 19.1961 17.3425 19.1889 17.262L19.8584 16.7685C20.0024 16.6635 20.042 16.4675 19.952 16.3135L19.2789 15.183C19.1889 15.029 18.9945 14.966 18.8289 15.036L18.055 15.3545C17.9218 15.2635 17.7815 15.183 17.6339 15.1165L17.5295 14.308C17.5079 14.133 17.3531 14 17.1731 14H15.8305C15.6469 14 15.4921 14.133 15.4705 14.308L15.3661 15.1165C15.2185 15.183 15.0782 15.2635 14.945 15.3545L14.1711 15.036C14.0055 14.966 13.8111 15.029 13.7211 15.183L13.048 16.317C12.958 16.471 12.9976 16.6635 13.1416 16.772L13.8111 17.2655C13.8039 17.3425 13.8003 17.4195 13.8003 17.5C13.8003 17.5805 13.8039 17.6575 13.8111 17.738L13.1416 18.2315C12.9976 18.3365 12.958 18.5325 13.048 18.6865L13.7211 19.817C13.8111 19.971 14.0055 20.034 14.1711 19.964L14.945 19.6455C15.0782 19.7365 15.2185 19.817 15.3661 19.8835L15.4705 20.692C15.4921 20.867 15.6469 21 15.8269 21H17.1695C17.3495 21 17.5043 20.867 17.5259 20.692L17.6303 19.8835C17.7779 19.817 17.9182 19.7365 18.0514 19.6455L18.8253 19.964C18.9909 20.034 19.1853 19.971 19.2753 19.817L19.9484 18.6865C20.0384 18.5325 19.9988 18.34 19.8548 18.2315L19.1853 17.738C19.1961 17.6575 19.1997 17.5805 19.1997 17.5ZM16.5144 18.725C15.8197 18.725 15.2545 18.1755 15.2545 17.5C15.2545 16.8245 15.8197 16.275 16.5144 16.275C17.2091 16.275 17.7743 16.8245 17.7743 17.5C17.7743 18.1755 17.2091 18.725 16.5144 18.725Z"
      fill="currentColor" />
  </svg>
</template>
