<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 20C13.1 20 14 19.1 14 18V14H13.7778C12.7643 14 12 13.1622 12 12.2L12.0039 6.6C12.0042 5.8119 12.5173 5.09885 13.2694 4.87364C13.3092 4.77522 13.3599 4.68181 13.4202 4.59524C13.0571 4.22821 12.5539 4 12 4C10.9 4 10 4.9 10 6V18C10 19.1 10.9 20 12 20ZM16.2697 14H19.2222C19.3858 14 19.5431 13.978 19.692 13.9369C19.8869 14.2454 20 14.6101 20 15V18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18V15C16 14.6364 16.0983 14.2946 16.2697 14ZM8 18C8 19.1 7.1 20 6 20C4.9 20 4 19.1 4 18V11C4 9.9 4.9 9 6 9C7.1 9 8 9.9 8 11V18Z"
      fill="currentColor" />
    <path
      d="M18.4444 5C18.2306 5 18.0556 5.18 18.0556 5.4V5.8H14.9444V5.4C14.9444 5.18 14.7694 5 14.5556 5C14.3417 5 14.1667 5.18 14.1667 5.4V5.8H13.7778C13.3461 5.8 13.0039 6.16 13.0039 6.6L13 12.2C13 12.64 13.3461 13 13.7778 13H19.2222C19.65 13 20 12.64 20 12.2V6.6C20 6.16 19.65 5.8 19.2222 5.8H18.8333V5.4C18.8333 5.18 18.6583 5 18.4444 5ZM19.2222 12.2H13.7778V8.2H19.2222V12.2ZM16.1111 9.4C16.1111 9.18 16.2861 9 16.5 9C16.7139 9 16.8889 9.18 16.8889 9.4C16.8889 9.62 16.7139 9.8 16.5 9.8C16.2861 9.8 16.1111 9.62 16.1111 9.4ZM14.5556 9.4C14.5556 9.18 14.7306 9 14.9444 9C15.1583 9 15.3333 9.18 15.3333 9.4C15.3333 9.62 15.1583 9.8 14.9444 9.8C14.7306 9.8 14.5556 9.62 14.5556 9.4ZM17.6667 9.4C17.6667 9.18 17.8417 9 18.0556 9C18.2694 9 18.4444 9.18 18.4444 9.4C18.4444 9.62 18.2694 9.8 18.0556 9.8C17.8417 9.8 17.6667 9.62 17.6667 9.4ZM16.1111 11C16.1111 10.78 16.2861 10.6 16.5 10.6C16.7139 10.6 16.8889 10.78 16.8889 11C16.8889 11.22 16.7139 11.4 16.5 11.4C16.2861 11.4 16.1111 11.22 16.1111 11ZM14.5556 11C14.5556 10.78 14.7306 10.6 14.9444 10.6C15.1583 10.6 15.3333 10.78 15.3333 11C15.3333 11.22 15.1583 11.4 14.9444 11.4C14.7306 11.4 14.5556 11.22 14.5556 11ZM17.6667 11C17.6667 10.78 17.8417 10.6 18.0556 10.6C18.2694 10.6 18.4444 10.78 18.4444 11C18.4444 11.22 18.2694 11.4 18.0556 11.4C17.8417 11.4 17.6667 11.22 17.6667 11Z"
      fill="currentColor" />
  </svg>
</template>
