<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1667 9.29167C14.1667 10.4906 13.1989 11.4583 12 11.4583C10.8011 11.4583 9.83333 10.4906 9.83333 9.29167C9.83333 8.09278 10.8011 7.125 12 7.125C13.1989 7.125 14.1667 8.09278 14.1667 9.29167Z"
      fill="currentColor" />
    <path
      d="M8.36722 13.17C9.42889 12.5417 10.6711 12.1806 12 12.1806C13.3289 12.1806 14.5711 12.5417 15.6328 13.17C16.0733 13.43 16.3333 13.9067 16.3333 14.4122V15.0694C16.3333 15.4667 16.0083 15.7917 15.6111 15.7917H8.38889C7.99167 15.7917 7.66667 15.4667 7.66667 15.0694V14.4122C7.66667 13.9067 7.92667 13.43 8.36722 13.17Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12ZM19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12Z"
      fill="currentColor" />
  </svg>
</template>
