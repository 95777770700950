<!-- eslint-disable vue/valid-template-root -->
<template></template>

<script setup lang="ts">
import { captureMessage, withScope } from "@sentry/vue"
import { onMounted } from "vue"

import { useProjectConfigStore } from "@/src/domains/project/config/config.store"

type Props = {
  message: string
  extras?: Record<string, any>
}

const props = defineProps<Props>()

const { projectConfig } = useProjectConfigStore()

onMounted(() => {
  withScope((scope) => {
    scope.setTag("projectId", projectConfig.id)
    scope.setTag("projectName", projectConfig.name)
    scope.setLevel("warning")
    if (props.extras) {
      scope.setExtra("Extra Props", props.extras)
    }

    captureMessage(props.message)
  })
})
</script>
