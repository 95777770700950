<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 16C13.01 16 13.91 15.61 14.62 15H9.38C10.09 15.61 10.99 16 12 16Z"
      fill="currentColor" />
    <path
      d="M8.56 14H15.45C15.71 13.55 15.89 13.04 15.96 12.5H8.06C8.12 13.04 8.29 13.55 8.56 14Z"
      fill="currentColor" />
    <path d="M12 8C10.99 8 10.09 8.39 9.38 9H14.62C13.91 8.39 13.01 8 12 8Z" fill="currentColor" />
    <path
      d="M8.56 10C8.3 10.45 8.12 10.96 8.05 11.5H15.95C15.88 10.96 15.71 10.45 15.44 10H8.56Z"
      fill="currentColor" />
    <path
      d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 18C8.69 18 6 15.31 6 12C6 8.69 8.69 6 12 6C15.31 6 18 8.69 18 12C18 15.31 15.31 18 12 18Z"
      fill="currentColor" />
  </svg>
</template>
