<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 4C19 2.9 18.1 2 17 2H15C14.45 2 14 2.45 14 3C14 3.55 14.45 4 15 4H17V6.65L13.52 11H10V7C10 6.45 9.55 6 9 6H6C3.79 6 2 7.79 2 10V13H4C4 14.66 5.34 16 7 16C8.66 16 10 14.66 10 13H14.48L19 7.35V4ZM7 14C6.45 14 6 13.55 6 13H8C8 13.55 7.55 14 7 14Z"
      fill="currentColor" />
    <path
      d="M9 3H6C5.45 3 5 3.45 5 4C5 4.55 5.45 5 6 5H9C9.55 5 10 4.55 10 4C10 3.45 9.55 3 9 3Z"
      fill="currentColor" />
    <path
      d="M19 10C17.34 10 16 11.34 16 13C16 14.66 17.34 16 19 16C20.66 16 22 14.66 22 13C22 11.34 20.66 10 19 10ZM19 14C18.45 14 18 13.55 18 13C18 12.45 18.45 12 19 12C19.55 12 20 12.45 20 13C20 13.55 19.55 14 19 14Z"
      fill="currentColor" />
    <path d="M7 19H11V17L17 20H13V22L7 19Z" fill="currentColor" />
  </svg>
</template>
