<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.27 12H13.73C14.66 12 15.36 11.17 15.21 10.25L14.91 8.46C14.67 7.04 13.44 6 12 6C10.56 6 9.33 7.04 9.09 8.47L8.79 10.26C8.64 11.17 9.34 12 10.27 12Z"
      fill="currentColor" />
    <path
      d="M1.66 11.11C1.53 11.37 1.48 11.68 1.56 11.99C1.72 12.68 2.32 13.02 3.09 12.99C3.09 12.99 4.58 12.99 5.04 12.99C5.87 12.99 6.55 12.41 6.55 11.7C6.55 11.56 6.52 11.43 6.48 11.3C6.47 11.27 6.47 11.25 6.49 11.22C6.58 11.06 6.63 10.88 6.63 10.69C6.63 10.38 6.49 10.09 6.27 9.87C6.24 9.84 6.24 9.81 6.25 9.77C6.32 9.57 6.32 9.34 6.26 9.12C6.1 8.69 5.71 8.4 5.27 8.38C5.24 8.38 5.22 8.37 5.2 8.35C5.03 8.14 4.72 8 4.37 8C4.07 8 3.8 8.1 3.62 8.26C3.59 8.29 3.56 8.29 3.53 8.28C3.39 8.22 3.23 8.19 3.07 8.19C2.42 8.19 1.89 8.68 1.83 9.31C1.83 9.33 1.82 9.35 1.8 9.37C1.51 9.63 1.34 10.02 1.39 10.42C1.42 10.64 1.51 10.85 1.64 11.02C1.67 11.04 1.67 11.08 1.66 11.11Z"
      fill="currentColor" />
    <path
      d="M16.24 13.65C15.07 13.13 13.63 12.75 12 12.75C10.37 12.75 8.93 13.14 7.76 13.65C6.68 14.13 6 15.21 6 16.39V17C6 17.55 6.45 18 7 18H17C17.55 18 18 17.55 18 17V16.39C18 15.21 17.32 14.13 16.24 13.65Z"
      fill="currentColor" />
    <path
      d="M1.22 14.58C0.48 14.9 0 15.62 0 16.43V17C0 17.55 0.45 18 1 18H4.5V16.39C4.5 15.56 4.73 14.78 5.13 14.1C4.76 14.04 4.39 14 4 14C3.01 14 2.07 14.21 1.22 14.58Z"
      fill="currentColor" />
    <path
      d="M22.78 14.58C21.93 14.21 20.99 14 20 14C19.61 14 19.24 14.04 18.87 14.1C19.27 14.78 19.5 15.56 19.5 16.39V18H23C23.55 18 24 17.55 24 17V16.43C24 15.62 23.52 14.9 22.78 14.58Z"
      fill="currentColor" />
    <path
      d="M22 11V10.5C22 9.4 21.1 8.5 20 8.5H18C17.58 8.5 17.35 8.98 17.61 9.31L18.31 9.94C18.12 10.25 18 10.61 18 11C18 12.1 18.9 13 20 13C21.1 13 22 12.1 22 11Z"
      fill="currentColor" />
  </svg>
</template>
