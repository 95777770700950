<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22Z" fill="currentColor" />
    <path
      d="M9 19H15C15.55 19 16 18.55 16 18C16 17.45 15.55 17 15 17H9C8.45 17 8 17.45 8 18C8 18.55 8.45 19 9 19Z"
      fill="currentColor" />
    <path
      d="M12 2C7.86 2 4.5 5.36 4.5 9.5C4.5 13.32 7.16 15.36 8.27 16H15.73C16.84 15.36 19.5 13.32 19.5 9.5C19.5 5.36 16.14 2 12 2Z"
      fill="currentColor" />
  </svg>
</template>
