import { EventHubPlugin } from "./vue_event_hub"

import type { App } from "vue"

const $toast = function (title, message = "", icon = "fa-info") {
  EventHubPlugin.$emit("toast", {
    title: title,
    text: message,
    icon: icon,
    deletable: true
  })
}

const $toastError = function (title, message = "", icon = "fa-exclamation", color = "error") {
  EventHubPlugin.$emit("toast", {
    title: title,
    text: message,
    icon: icon,
    deletable: true,
    iconColor: color
  })
}

const $toastAction = function (
  title,
  message = "",
  icon = "fa-info",
  actionMessage = "",
  callback
) {
  EventHubPlugin.$emit("toast", {
    title: title,
    text: message,
    icon: icon,
    deletable: true,
    callback: callback,
    action: actionMessage
  })
}
const plugin = {
  // FIXME-8311 check if there are consequences
  install(app: App) {
    app.config.globalProperties.$toast = $toast

    app.config.globalProperties.$toastError = $toastError

    app.config.globalProperties.$toastAction = $toastAction
  }
}

declare module "@vue/runtime-core" {
  //Bind to `this` keyword
  interface ComponentCustomProperties {
    $toast: (title: string, message?: string, icon?: string) => void
    $toastError: (title: string, message?: string, icon?: string, color?: string) => void
    $toastAction: (
      title: string,
      message: string,
      icon: string,
      actionMessage: string,
      callback: () => void
    ) => void
  }
}

export default plugin
export { $toast, $toastError, $toastAction }
