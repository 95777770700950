<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2851 17.2894C18.6751 16.8994 18.6751 16.2694 18.2851 15.8794L14.4151 11.9994L18.2951 8.11937C18.6851 7.72937 18.6851 7.09937 18.2951 6.70937C17.9051 6.31937 17.2751 6.31937 16.8851 6.70937L12.2951 11.2994C11.9051 11.6894 11.9051 12.3194 12.2951 12.7094L16.8851 17.2994C17.2651 17.6794 17.8951 17.6794 18.2851 17.2894Z"
      fill="currentColor" />
    <path
      d="M11.6951 17.2894C12.0851 16.8994 12.0851 16.2694 11.6951 15.8794L7.8251 11.9994L11.7051 8.11937C12.0951 7.72937 12.0951 7.09937 11.7051 6.70937C11.3151 6.31937 10.6851 6.31937 10.2951 6.70937L5.7051 11.2994C5.3151 11.6894 5.3151 12.3194 5.7051 12.7094L10.2951 17.2994C10.6751 17.6794 11.3051 17.6794 11.6951 17.2894Z"
      fill="currentColor" />
  </svg>
</template>
