import "@/src/style/components/_tooltip.scss"

function getTooltipContainer() {
  let container = document.getElementById("dr-tooltip")
  if (!container) {
    container = document.createElement("div")
    container.id = "dr-tooltip"
    document.body.appendChild(container)
  }
  return container
}

function getCoords(elem) {
  // crossbrowser version
  const box = elem.getBoundingClientRect()

  const body = document.body
  const docEl = document.documentElement

  const scrollTop = globalThis.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = globalThis.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0

  const top = box.top + scrollTop - clientTop - 8
  const left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left), width: box.width, height: box.height }
}
export default {
  mounted: function (el, binding) {
    if (el._tooltip_mouseoverHandler) {
      el.removeEventListener("mouseover", el._tooltip_mouseoverHandler)
    }

    if (binding.modifiers.inline && !binding.value) {
      return // do nothing
    }

    el._tooltip_mouseoutHandler = function () {
      const container = getTooltipContainer()
      container.className = ""
    }

    el._tooltip_mouseoverHandler = function () {
      const innerTooltip = el.getElementsByClassName("v-dr-tooltip")
      const showTooltip = innerTooltip.length > 0 || binding?.modifiers?.inline
      if (!showTooltip) {
        return
      }
      const container = getTooltipContainer()
      container.className = "show"

      if (binding.modifiers.inline) {
        container.innerHTML = binding.value
      } else {
        container.innerHTML = innerTooltip[0].innerHTML
      }

      if (container.innerHTML.trim().length === 0) {
        container.className = ""
      }

      const containerBox = container.getBoundingClientRect()
      const pos = getCoords(el)

      if (pos.top < containerBox.height) {
        container.style.top = `${pos.top + el.getBoundingClientRect().height}px`
        container.classList.add("dr-tooltip--bottom")
      } else {
        container.style.top = `${pos.top - containerBox.height - 3}px`
      }

      container.style.left = `${pos.left - containerBox.width / 2 + pos.width / 2}px`

      el.addEventListener("mouseout", el._tooltip_mouseoutHandler)
    }

    el.addEventListener("mouseover", el._tooltip_mouseoverHandler)
  },

  unmounted: function (el) {
    const container = getTooltipContainer()
    container.className = ""
    if (el._tooltip_mouseoverHandler) {
      el.removeEventListener("mouseover", el._tooltip_mouseoverHandler)
    }
    if (el._tooltip_mouseoutHandler) {
      el.removeEventListener("mouseout", el._tooltip_mouseoutHandler)
    }
  }
}
