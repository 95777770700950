<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0068 4C15.2333 4 13.3233 4.4 12.0045 5.5C10.6858 4.4 8.77581 4 7.00227 4C5.68349 4 4.28286 4.22 3.1096 4.79C2.44566 5.12 2 5.83 2 6.64V17.92C2 19.22 3.1096 20.18 4.25557 19.86C5.14689 19.61 6.09277 19.5 7.00227 19.5C8.4211 19.5 9.93088 19.76 11.1496 20.42C11.6953 20.72 12.3138 20.72 12.8504 20.42C14.0691 19.75 15.5789 19.5 16.9977 19.5C17.9072 19.5 18.8531 19.61 19.7444 19.86C20.8904 20.19 22 19.23 22 17.92V6.64C22 5.83 21.5543 5.12 20.8904 4.79C19.7262 4.22 18.3256 4 17.0068 4ZM20.1901 16.73C20.1901 17.36 19.6626 17.82 19.0987 17.71C18.4166 17.57 17.7071 17.51 17.0068 17.51C15.4607 17.51 13.2324 18.16 12.0045 19.01V7.5C13.2324 6.65 15.4607 6 17.0068 6C17.8436 6 18.6712 6.09 19.4625 6.28C19.8809 6.38 20.1901 6.79 20.1901 7.26V16.73Z"
      fill="currentColor" />
    <path
      d="M13.8054 10.51C13.5143 10.51 13.2506 10.31 13.1596 9.99C13.0414 9.6 13.2415 9.17 13.5962 9.05C14.9968 8.55 16.8067 8.39 18.4711 8.6C18.844 8.65 19.1169 9.02 19.0714 9.43C19.0259 9.84 18.6894 10.14 18.3165 10.09C16.8431 9.9 15.2333 10.05 14.0146 10.48C13.9418 10.49 13.869 10.51 13.8054 10.51Z"
      fill="currentColor" />
    <path
      d="M13.8054 13.17C13.5143 13.17 13.2506 12.97 13.1596 12.65C13.0414 12.26 13.2415 11.83 13.5962 11.71C14.9877 11.21 16.8067 11.05 18.4711 11.26C18.844 11.31 19.1169 11.68 19.0714 12.09C19.0259 12.5 18.6894 12.8 18.3165 12.75C16.8431 12.56 15.2333 12.71 14.0146 13.14C13.9418 13.16 13.869 13.17 13.8054 13.17Z"
      fill="currentColor" />
    <path
      d="M13.8054 15.83C13.5143 15.83 13.2506 15.63 13.1596 15.31C13.0414 14.92 13.2415 14.49 13.5962 14.37C14.9877 13.87 16.8067 13.71 18.4711 13.92C18.844 13.97 19.1169 14.34 19.0714 14.75C19.0259 15.16 18.6894 15.45 18.3165 15.41C16.8431 15.22 15.2333 15.37 14.0146 15.8C13.9418 15.82 13.869 15.83 13.8054 15.83Z"
      fill="currentColor" />
  </svg>
</template>
