<template>
  <VoltaIcon
    v-if="voltaEquivalence"
    :icon="voltaEquivalence"
    @click="emit('click')"
    :style="voltaStyleAttribute" />
  <i
    v-else-if="isFontAwesomeIcon"
    :class="iconAttributes.class"
    :style="iconAttributes.style"
    @click="emit('click')" />
  <VoltaIcon
    v-else-if="isVoltaIcon && voltaIcon"
    :icon="voltaIcon"
    @click="emit('click')"
    :style="voltaStyleAttribute" />
  <template v-else-if="props.icon === ''"></template>
  <DrCaptureMessage
    v-else
    message="dr-icon was called with unknown icon attribute"
    :extras="{ icon: props.icon }" />
</template>

<script setup lang="ts">
import { computed } from "vue"

import VoltaIcon from "@/src/domains/volta/components/icons/volta-icon.vue"
import { fa4to5 } from "@/src/utils/icons"

import { faToVoltaMapping } from "./dr-icon"
import DrCaptureMessage from "./sentry/dr-capture-message.vue"

import type { Icon } from "../domains/volta/components/icons/volta-icon"

type Props = {
  icon?: string
  size?: string | number
  color?: string
}

const FONT_AWESOME_PREFIX = "fa"
const VOLTA_PREFIX = "volta-"

function fontAwesomeAttributes({
  icon,
  size,
  color
}: {
  [P in keyof Props]-?: Props[P]
}): {
  class: string
  style: string
} {
  const classAttribute = [
    icon.match(/far |fas |fal |fad |fab |fa /g)?.[0].trim() || "fa",
    icon
      .split(" ")
      .filter((f) => f.startsWith("fa-"))
      .map((m) => fa4to5(m))
      .join(" ")
  ]

  const style: string[] = []

  if (size !== "100%") {
    style.push(`font-size: ${size}px`)
  }

  if (color) {
    style.push(`color: ${color}`)
  }

  return {
    class: classAttribute.join(" "),
    style: style.join("; ")
  }
}

const props = withDefaults(defineProps<Props>(), {
  icon: "",
  size: "100%",
  color: ""
})

const emit = defineEmits(["click"])

/**
 * Exact mapping matching to avoid name collisions.
 */
const voltaEquivalence = computed<Icon | undefined>(() => {
  if (!(props.icon in faToVoltaMapping)) {
    return undefined
  }

  return faToVoltaMapping[props.icon]
})

const isFontAwesomeIcon = computed<boolean>(() => {
  return props.icon.slice(0, 2) === FONT_AWESOME_PREFIX
})

const isVoltaIcon = computed<boolean>(() => {
  return props.icon.startsWith(VOLTA_PREFIX)
})

const voltaIcon = computed<Icon | undefined>(() => {
  if (!isVoltaIcon.value) {
    return undefined
  }

  return props.icon.replace(VOLTA_PREFIX, "") as Icon
})

const iconAttributes = computed<ReturnType<typeof fontAwesomeAttributes>>(() => {
  if (isFontAwesomeIcon.value) {
    return fontAwesomeAttributes(props)
  }

  return {
    class: "",
    style: ""
  }
})

const voltaStyleAttribute = computed<string>(() => {
  const style: string[] = []

  if (props.size !== "100%") {
    style.push(`width: ${props.size}px`)
    style.push(`height: ${props.size}px`)
  }

  if (props.color) {
    style.push(`color: ${props.color}`)
  }

  return style.join("; ")
})
</script>

<style lang="scss" scoped>
.svg-icon {
  display: inline-block;
  vertical-align: text-bottom;
  margin: auto;

  [height="32px"] {
    margin-top: calc(50% - 16px);
  }

  [height="64px"] {
    margin-top: calc(50% - 32px);
  }
}
</style>
