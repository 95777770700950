import { STORE_KEY_USER } from "./constants"

import type { UserInfo } from "./types"
import type { UserConfig } from "@/src/typings/globals"

export function getUserInfo(): UserInfo {
  const localUser = localStorage.getItem(STORE_KEY_USER)

  if (!localUser) {
    return {}
  }

  try {
    const user: UserInfo = JSON.parse(localUser)

    return user
  } catch (e) {
    console.warn(`Stored user data is not JSON parsable: "${localUser}"`)
  }

  return {}
}

export function storeUserInfo(user: UserInfo): void {
  try {
    localStorage.setItem(STORE_KEY_USER, JSON.stringify(user))
  } catch (e) {
    const baseLog = "User info could not be stored to local storage"

    if (e instanceof Error) {
      console.warn(`${baseLog} (${e.message})`)
    } else {
      console.warn(`${baseLog} (no error provided)`)
    }
  }
}

export function loadAnonymousUser(includedJsonData: Record<string, any>): Promise<UserConfig> {
  return Promise.resolve({
    ...anonymousUser,
    locale: includedJsonData?.locale || "en",
    global: includedJsonData
  })
}

export const anonymousUser: UserConfig = {
  email: "",
  id: "anonymous",
  is_admin: false,
  is_deepki: false,
  is_super_admin: false,
  is_super_deepki: false,
  login_type: undefined,
  global: {},
  groups: [],
  scope: "*",
  locale: "en",
  name: "Anonymous",
  surfaceUnit: "m2",
  currency: "EUR",
  unit_system: {
    floor_area: "m2",
    energy: "kwh",
    volume: "m3",
    weight: "kg",
    temperature: "celsius_degrees"
  }
}
