<template>
  <div :class="{ drLabel: true, 'drLabel--outline': outline }">
    <div class="flex-row-sb">
      <label :for="id" class="label" v-if="label">
        {{ label }}
        <sup v-if="required" class="input--required">
          <DrIcon icon="fas fa-asterisk" />
        </sup>
        <DrIcon
          v-if="normalizedDescription"
          icon="fa-info-circle"
          v-dr-tooltip.inline="normalizedDescription" />
        <div
          v-if="error && error.message"
          :class="isWarning ? 'label-error warning' : 'label-error'">
          <DrIcon :icon="isWarning ? 'fa-exclamation-triangle' : 'fa-ban'" />
          {{ error.message }}
        </div>
      </label>
      <slot name="topRight" />
    </div>
    <div class="input-line" :class="{ inactive: disabled }">
      <slot />
      <div class="note" v-if="note">
        <span class="sublabel">{{ note }}</span>
      </div>

      <div class="field-unit" v-if="unit">
        {{ props.unit }}
      </div>
    </div>
    <template v-if="normalizedErrors.length">
      <div
        v-for="(normalizedError, idx) in normalizedErrors"
        :key="'error' + idx"
        class="label-error">
        <DrIcon icon="fa-ban" />
        {{ normalizedError }}
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"

import DrIcon from "@/src/ui/dr-icon.vue"

type Props = {
  id?: string
  required?: boolean
  label?: string
  description?: object | string | any
  error?: Record<string, string>
  disabled?: boolean
  note?: string
  normalizedErrors?: string[]
  unit?: string
  outline?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  label: "",
  description: () => ({}),
  required: false,
  error: () => ({}),
  disabled: false,
  note: "",
  normalizedErrors: () => [],
  unit: "",
  outline: false
})

const normalizedDescription = computed<string>(() => {
  if (props.description?.display_name) {
    return props.description.display_name
  } else if (typeof props.description === "string") {
    return props.description
  }
  return ""
})

const isWarning = computed(() => !!props.error?.optional)
</script>

<style lang="scss" scoped>
.drLabel {
  font-size: $dr-font-size-body;
  line-height: 1.35;

  &--outline {
    background-color: $dr-green-heritage-lightest;
  }
}

.label {
  display: inline-block;
  z-index: 4;
  width: auto;
  margin-bottom: $dr-margin-small;
  font-weight: 400;
  text-transform: initial;

  &-error {
    color: $dr-error;
    display: block;
    margin: 1px;

    &.warning {
      color: $dr-warning;
    }
  }
}

.input-line {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  flex: 1;
}

.note {
  position: relative;
  height: $dr-font-size-body;
  min-width: 80px;
  margin-left: 12px;

  .sublabel {
    position: absolute;
    width: 100%;
  }
}

.inactive {
  pointer-events: none;
  opacity: 0.7;
}

.field-unit {
  min-width: 80px;
  margin-left: 12px;
}
</style>
