// simple plugin wich expose project and user config in Vue.$config
import configUtils from "@/src/utils/config"

import type { UserConfig } from "@/src/typings/globals"
import type { App } from "vue"

/* TODO: Destroy this line once $user is not used anymore */
const userConfigHandler = {
  get(_obj: UserConfig, prop: keyof UserConfig): UserConfig[typeof prop] {
    return globalThis.user_config?.[prop]
  }
}

/* TODO: Destroy this line once $user is not used anymore */
const $user = new Proxy({} as UserConfig, userConfigHandler)
const $userHasAccess = configUtils.userHasAccess

export default {
  install(app: App) {
    /* TODO: Destroy this line once $user is not used anymore */
    app.config.globalProperties.$user = $user
    app.config.globalProperties.$userHasAccess = $userHasAccess
    app.config.globalProperties.$getFluidConfig = (fluid: String) => {
      if (globalThis.project_config?.collect?.fluids) {
        return globalThis.project_config.collect.fluids.find((f) => f.id == fluid)
      }
      return null
    }

    app.provide("$user", $user)
    app.provide("$userHasAccess", $userHasAccess)
  }
}

export { $user, $userHasAccess }

declare module "@vue/runtime-core" {
  //Bind to `this` keyword
  interface ComponentCustomProperties {
    $user: UserConfig
    $userHasAccess: (accessObject: any) => boolean
    $getFluidConfig: (fluid: String) => any
  }
}
