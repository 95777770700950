import { MENU_TYPES } from "./consts"
import { isPanelType, isSectionType } from "./type-guards"

import type {
  Menu,
  PanelTypeMenu,
  ProjectConfig,
  SectionTypeMenu,
  StrictProjectPanel
} from "@/src/typings/globals"

export const setMenu = (projectConfig: ProjectConfig, translate: Function) => {
  const hardcodedDisplayNames: Record<string, string> = {
    portfolio_data: translate({ defaultMessage: "Portfolio Attributes" }),
    pm_dashboard: translate({ defaultMessage: "Collection Progress" }),
    control_room: translate({ defaultMessage: "Control Room" }),
    investment_plan: translate({ defaultMessage: "Investment Plan" }),
    action_tracker: translate({ defaultMessage: "Action Tracker" }),
    supervision: translate({ defaultMessage: "Supervision" }),
    supplier_accounts_management: translate({ defaultMessage: "Supplier Accounts Management" })
  }

  const defaultDisplayNames: Record<string, string> = {
    mandates: translate({ defaultMessage: "Mandate Management Board" })
  }

  const panelsAsObject: Record<string, StrictProjectPanel> = projectConfig.panels.reduce(
    (acc, panel) => {
      acc[panel.id] = panel
      return acc
    },
    {}
  )

  const menuConfig = projectConfig.menu
    .map((menuItem: Menu) => {
      if (isSectionType(menuItem)) {
        const submenu = menuItem.submenu.map(
          ({ id }) =>
            ({
              id: panelsAsObject[id].id || id,
              type: MENU_TYPES.PANEL,
              display_name:
                hardcodedDisplayNames[id] ||
                panelsAsObject[id].display_name ||
                defaultDisplayNames[id]
            }) as PanelTypeMenu
        )

        return {
          id: menuItem.id,
          display_name: menuItem.display_name,
          type: "section",
          submenu
        } as SectionTypeMenu
      }
      return {
        id: panelsAsObject[menuItem.id].id || menuItem.id,
        type: MENU_TYPES.PANEL,
        display_name: hardcodedDisplayNames[menuItem.id] || panelsAsObject[menuItem.id].display_name
      } as PanelTypeMenu
    })
    .filter((menuItem) => {
      return isPanelType(menuItem) || (isSectionType(menuItem) && menuItem.submenu.length)
    })

  return menuConfig
}
