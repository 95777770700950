<template>
  <router-link
    v-if="to"
    v-dr-tooltip.inline="showTooltip"
    :to="to"
    :class="btnClasses"
    class="button--inline"
    :disabled="disabled">
    <div class="button-container">
      <DrIcon v-if="leftIcon || icon" :icon="leftIcon || icon" :style="iconStyle" />
      <div :class="innerClasses">
        <slot />
      </div>
      <DrIcon v-if="rightIcon" :icon="rightIcon" />
      <span v-if="itemsCount" class="button__badge">
        {{ itemsCount }}
      </span>
    </div>
  </router-link>
  <button
    v-else
    :key="showTooltip"
    v-dr-tooltip.inline="showTooltip"
    :class="btnClasses"
    :disabled="disabled || loading"
    @click="$emit('click')">
    <div class="button-container" tabindex="-1">
      <DrIcon v-if="loading" icon="fad fa-spinner-third" class="spinner spinner--large" />
      <template v-else>
        <DrIcon v-if="leftIcon || icon" :icon="leftIcon || icon" :style="iconStyle" />
        <div :class="innerClasses">
          <slot />
        </div>
        <DrIcon v-if="rightIcon" :icon="rightIcon" />
        <span v-if="itemsCount" class="button__badge">
          {{ itemsCount }}
        </span>
      </template>
    </div>
  </button>
</template>

<script>
import "@/src/directives/tooltip"
import DrIcon from "../dr-icon.vue"

export default {
  components: {
    DrIcon
  },
  props: {
    rightIcon: {
      type: String,
      default: null
    },
    leftIcon: {
      type: String,
      default: null
    },
    itemsCount: {
      type: Number,
      default: null
    },
    to: {
      type: [String, Object], //RouteLocationRaw
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: undefined
    },
    size: {
      type: String,
      default: "small",
      validator: (val) => ["small", "large"].includes(val)
    },

    icon: {
      type: String,
      default: ""
    },

    iconColor: {
      type: String,
      default: ""
    },

    type: {
      type: String,
      default: "secondary",
      validator: (val) => ["primary", "secondary", "unsafe", "danger", "empty"].includes(val)
    }
  },

  mounted() {
    this.allowTooltip()
  },

  emits: ["click"],

  computed: {
    iconStyle() {
      const style = {}
      if (this.iconColor && !this.disabled) {
        style.color = this.iconColor
      }
      if (this.rightIcon) {
        style.marginLeft = "8px"
      } else if (this.leftIcon || this.icon) {
        style.marginRight = "8px"
      }
      return style
    },
    showTooltip() {
      if (this.tooltip) {
        return this.tooltip
      }
      if (this.innerClasses.find((f) => f === "button-content--ellipsis")) {
        return this.$slots?.default()[0]?.text
      }
      return false
    },
    btnClasses() {
      let spec = `button-${this.type}`
      if (this.disabled) {
        spec += "--disabled"
      }
      return ["button", `button--${this.size}`, spec]
    }
  },

  methods: {
    allowTooltip() {
      if (!this.$el?.querySelector || !this.innerClasses) {
        return
      }
      const containerWidth = this.$el.querySelector(".button-container")?.clientWidth
      const textWidth = this.$el.querySelector(".button-content")?.clientWidth

      const hasEllipsisClass =
        this.innerClasses?.find((f) => f === "button-content--ellipsis") || false
      if (textWidth < containerWidth && hasEllipsisClass) {
        this.innerClasses = this.innerClasses.filter((f) => f !== "button-content--ellipsis")
      } else if (textWidth >= containerWidth && !hasEllipsisClass) {
        this.innerClasses.push("button-content--ellipsis")
      }
    }
  },

  // using mounted function is breaking the tests
  created() {
    if (this.icon || this.leftIcon || this.rightIcon) {
      this.innerClasses.push("button-content-minimal")
    }
  },

  data() {
    return {
      innerClasses: ["button-content"]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/src/style/atoms/_buttons.scss";
.button-content-minimal {
  @include breakpoint(mobile) {
    display: none;
  }
}
.button__badge {
  position: absolute;
  right: -8px;
  top: -8px;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 16px;
  min-height: 16px;

  border-radius: 50%;
  background-color: $dr-error;
  color: white;
  font-size: $dr-font-size-caption;
  line-height: $dr-font-size-caption;
}
</style>
