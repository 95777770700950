import axios from "axios"
import { stringify } from "qs"

import { getScopeFromQueryParams } from "../domains/npx/scope-controller/utils"

const headers = {
  Accept: "application/json, */*;q=0.8" // Ask for json first, then other media
}
if (__NPX__) {
  // Tell the server about NPX context
  headers["App-NPX"] = "true"
}

// Export our own instance of Axios
const instance = axios.create({
  paramsSerializer: (params): any => {
    if (params instanceof URLSearchParams) {
      return params
    }

    return stringify(params, { arrayFormat: "repeat" })
  },
  headers
})

instance.interceptors.request.use((config) => {
  const scopeQueryParams = getScopeFromQueryParams()

  if (config.params?.scope === null) {
    delete config.params.scope
  } else if (__NPX__ && scopeQueryParams) {
    config.params = { scope: scopeQueryParams, ...config.params }
  }

  if (config.method !== "get") {
    try {
      config.headers["X-CSRFToken"] =
        config.headers["X-CSRFToken"] || globalThis.user_config.global.csrf_token
    } catch (error) {
      // CSRF not ready yet
      throw error
    }
  }
  return config
})

export default instance
