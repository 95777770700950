<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 10.9075C17.34 10.9075 17.67 10.9475 18 10.9975V7.48753C18 6.68753 17.53 5.96753 16.8 5.65753L11.3 3.25753C10.79 3.03753 10.21 3.03753 9.7 3.25753L4.2 5.65753C3.47 5.97753 3 6.69753 3 7.48753V11.0875C3 15.6275 6.2 19.8775 10.5 20.9075C11.05 20.7775 11.58 20.5875 12.1 20.3575C11.41 19.3775 11 18.1875 11 16.9075C11 13.5975 13.69 10.9075 17 10.9075Z"
      fill="currentColor" />
    <path
      d="M17 12.9075C14.79 12.9075 13 14.6975 13 16.9075C13 19.1175 14.79 20.9075 17 20.9075C19.21 20.9075 21 19.1175 21 16.9075C21 14.6975 19.21 12.9075 17 12.9075ZM17 14.2875C17.62 14.2875 18.12 14.7975 18.12 15.4075C18.12 16.0175 17.61 16.5275 17 16.5275C16.39 16.5275 15.88 16.0175 15.88 15.4075C15.88 14.7975 16.38 14.2875 17 14.2875ZM17 19.6575C16.07 19.6575 15.26 19.1975 14.76 18.4875C14.81 17.7675 16.27 17.4075 17 17.4075C17.73 17.4075 19.19 17.7675 19.24 18.4875C18.74 19.1975 17.93 19.6575 17 19.6575Z"
      fill="currentColor" />
  </svg>
</template>
