<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8208 12.5249C10.6008 12.5149 10.3808 12.5049 10.1508 12.5049C7.73076 12.5049 5.47076 13.1749 3.54076 14.3249C2.66076 14.8449 2.15076 15.8249 2.15076 16.8549V18.5049C2.15076 19.0549 2.60076 19.5049 3.15076 19.5049H11.4108C10.6208 18.3749 10.1508 16.9949 10.1508 15.5049C10.1508 14.4349 10.4008 13.4349 10.8208 12.5249Z"
      fill="currentColor" />
    <path
      d="M10.1508 11.5049C12.3599 11.5049 14.1508 9.71402 14.1508 7.50488C14.1508 5.29574 12.3599 3.50488 10.1508 3.50488C7.94162 3.50488 6.15076 5.29574 6.15076 7.50488C6.15076 9.71402 7.94162 11.5049 10.1508 11.5049Z"
      fill="currentColor" />
    <path
      d="M20.9008 15.5049C20.9008 15.2849 20.8708 15.0849 20.8408 14.8749L21.6808 14.1449C21.8608 13.9849 21.9008 13.7249 21.7808 13.5149L21.1908 12.4949C21.0708 12.2849 20.8208 12.1949 20.6008 12.2749L19.5408 12.6349C19.2208 12.3649 18.8608 12.1549 18.4608 12.0049L18.2408 10.9149C18.1908 10.6849 17.9908 10.5149 17.7508 10.5149H16.5708C16.3308 10.5149 16.1308 10.6849 16.0808 10.9149L15.8608 12.0049C15.4608 12.1549 15.1008 12.3649 14.7808 12.6349L13.7208 12.2749C13.4908 12.1949 13.2508 12.2949 13.1308 12.4949L12.5408 13.5149C12.4208 13.7249 12.4608 13.9849 12.6408 14.1449L13.4808 14.8749C13.4508 15.0849 13.4208 15.2849 13.4208 15.5049C13.4208 15.7249 13.4508 15.9249 13.4808 16.1349L12.6408 16.8649C12.4608 17.0249 12.4208 17.2849 12.5408 17.4949L13.1308 18.5149C13.2508 18.7249 13.5008 18.8149 13.7208 18.7349L14.7808 18.3749C15.1008 18.6449 15.4608 18.8549 15.8608 19.0049L16.0808 20.0949C16.1308 20.3249 16.3308 20.4949 16.5708 20.4949H17.7508C17.9908 20.4949 18.1908 20.3249 18.2408 20.0949L18.4608 19.0049C18.8608 18.8549 19.2208 18.6449 19.5408 18.3749L20.6008 18.7349C20.8308 18.8149 21.0708 18.7149 21.1908 18.5149L21.7808 17.4949C21.9008 17.2849 21.8608 17.0249 21.6808 16.8649L20.8408 16.1349C20.8708 15.9249 20.9008 15.7249 20.9008 15.5049ZM17.1508 17.5049C16.0508 17.5049 15.1508 16.6049 15.1508 15.5049C15.1508 14.4049 16.0508 13.5049 17.1508 13.5049C18.2508 13.5049 19.1508 14.4049 19.1508 15.5049C19.1508 16.6049 18.2508 17.5049 17.1508 17.5049Z"
      fill="currentColor" />
  </svg>
</template>
