<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 9V8C22 7.45 21.55 7 21 7C20.45 7 20 7.45 20 8V9H19C18.45 9 18 9.45 18 10C18 10.55 18.45 11 19 11H20V12C20 12.55 20.45 13 21 13C21.55 13 22 12.55 22 12V11H23C23.55 11 24 10.55 24 10C24 9.45 23.55 9 23 9H22Z"
      fill="currentColor" />
    <path
      d="M8 12C10.21 12 12 10.21 12 8C12 5.79 10.21 4 8 4C5.79 4 4 5.79 4 8C4 10.21 5.79 12 8 12Z"
      fill="currentColor" />
    <path d="M8 13C5.33 13 0 14.34 0 17V20H16V17C16 14.34 10.67 13 8 13Z" fill="currentColor" />
    <path
      d="M12.51 4.05C13.43 5.11 14 6.49 14 8C14 9.51 13.43 10.89 12.51 11.95C14.47 11.7 16 10.04 16 8C16 5.96 14.47 4.3 12.51 4.05Z"
      fill="currentColor" />
    <path
      d="M16.53 13.83C17.42 14.66 18 15.7 18 17V20H20V17C20 15.55 18.41 14.49 16.53 13.83Z"
      fill="currentColor" />
  </svg>
</template>
