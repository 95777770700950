import * as Sentry from "@sentry/vue"
import dayjs, {
  locale,
  isDayjs,
  extend,
  type OpUnitType,
  type Dayjs,
  type ManipulateType,
  type QUnitType
} from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import calendar from "dayjs/plugin/calendar"
import duration from "dayjs/plugin/duration"
import d_isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import localeData from "dayjs/plugin/localeData"
import localizedFormat from "dayjs/plugin/localizedFormat"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"

// Dayjs plugins
extend(utc)
extend(localeData)
extend(relativeTime)
extend(calendar)
extend(duration)
extend(d_isSameOrBefore)
extend(localizedFormat)
extend(arraySupport)

export type DateInput = string | number | Date | null | Dayjs
const ERROR_MESSAGE = {
  DATE: "Error in date",
  DURATION: "Duration Error",
  OFFSET: "Wrong Offset",
  UNKNOWN: "Unknown error",
  MONTHS: "Month index must be between 0 and 11 (included)",
  INVALID_DATE: "Invalid date"
} as const

export const DATE_TIME_FORMATS = {
  /**
   * ex: 09/04/1986
   */
  shortDateFormat: "L",
  /**
   * ex: September 4, 1986
   */
  longDateFormat: "LL",
  /**
   * ex: 8:30 PM
   */
  shortTimeFormat: "LT",
  /**
   * ex: September 4, 1986 8:30 PM
   */
  longDateTimeFormat: "LLL",
  /**
   * ex: Thursday, September 4, 1986 8:30 PM
   */
  fullLongDateTimeFormat: "LLLL",
  /**
   * ex: 09
   */
  monthOnlyFormat: "MM",
  /**
   * ex: Sep
   */
  longMonthOnlyFormat: "MMM",
  /**
   * ex: 1986-09-04
   */
  isoOnlyDateFormat: "YYYY-MM-DD",
  /**
   * ex: 1986-09
   */
  numericYearAndMonth: "YYYY-MM",
  /**
   * ex: Sep 1986
   */
  shortMonthFullYear: "MMM YYYY",
  /**
   * ex: 12 Sep 1986
   */
  shortMonthFullYearWithDay: "DD MMM YYYY",
  /**
   * ex: 1986
   */
  numericYear: "YYYY",

  /**
   * Su Mo ... Fr Sa
   */
  dayOfWeek: "dd",

  /**
   * 1...31
   */
  numericDayOfMonth: "DD",

  /**
   * ISO8601 format
   * YYYY-MM-DDTHH:mm:ssZ
   */
  ISO8601: "YYYY-MM-DDTHH:mm:ssZ",

  /**
   * ISO8601 format
   * YYYY-MM-DDTHH:mm:ssZ
   */
  ISO8601AtMidnight: "YYYY-MM-DDT00:00:00Z",

  /**
   * Unix timestamp
   * ex: 1410715640579
   */
  timestamp: "x"
} as const

// type DateTimeFormatsAllowed = typeof DATE_TIME_FORMATS[keyof typeof DATE_TIME_FORMATS]

type Num = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
type YYYY = `19${0 | Num}${0 | Num}` | `20${0 | Num}${0 | Num}` // 1900 <= X < 2100
type MM = `0${Num}` | `1${0 | 1 | 2}` // 01 <= X <= 12
type DD = `0${Num}` | `1${0 | Num}` | `2${0 | Num}` | `3${0 | 1}` // 01 <= X <= 31
export type UnitOfTime = OpUnitType

type StartAndEndOfParams = { date: DateInput; unit?: OpUnitType }

/*
  dayjs note : For additional functions, we can only use one of each step to add and remove this step
  example : add(-5, 'd') is the same as remove(5, 'd')
*/
type AdditionTimeParams = {
  date: DateInput
  time: number | any
  unit?: ManipulateType
}

export type DateString = `${YYYY}-${MM}-${DD}`

export const setLocale = async () => {
  let userLocale = globalThis.user_config.locale
  if (userLocale === "en-US") {
    userLocale = "en"
  }
  let importedLocale

  if (userLocale === "en") {
    importedLocale = await import(`dayjs/locale/en`)
  } else if (userLocale === "en-GB") {
    importedLocale = await import(`dayjs/locale/en-gb`)
  } else if (userLocale === "it") {
    importedLocale = await import(`dayjs/locale/it`)
  } else if (userLocale === "fr") {
    importedLocale = await import(`dayjs/locale/fr`)
  } else if (userLocale === "es") {
    importedLocale = await import(`dayjs/locale/es`)
  } else if (userLocale === "da") {
    importedLocale = await import(`dayjs/locale/da`)
  } else if (userLocale === "de") {
    importedLocale = await import(`dayjs/locale/de`)
  } else if (userLocale === "cs") {
    importedLocale = await import(`dayjs/locale/cs`)
  } else if (userLocale === "hu") {
    importedLocale = await import(`dayjs/locale/hu`)
  } else if (userLocale === "nl") {
    importedLocale = await import(`dayjs/locale/nl`)
  } else if (userLocale === "pt") {
    importedLocale = await import(`dayjs/locale/pt`)
  } else if (userLocale === "pl") {
    importedLocale = await import(`dayjs/locale/pl`)
  }
  locale(importedLocale)
}

const createDate = (date: DateInput): Dayjs => {
  try {
    const mDate = dayjs(date)
    if (!isValidDate(mDate)) {
      const error = new Error(
        `${ERROR_MESSAGE.INVALID_DATE}: the suspicious date input is ${date}  `
      )
      Sentry.captureException(error, {
        fingerprint: [ERROR_MESSAGE.INVALID_DATE]
      })
      throw error
    }
    return mDate
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

/**
 * Parses a date input with an expected format
 */
export const parseDate = ({ date, format }: { date: DateInput; format?: string }): Date => {
  let _date = date
  if (typeof date === "number") {
    _date = date.toString()
  }
  const parsedDate = format ? dayjs(_date, format) : dayjs(_date)

  if (parsedDate.isValid()) {
    return parsedDate.toDate()
  }

  throw Error(ERROR_MESSAGE.DATE)
}

/**
 * Formats the date according to its input format
 * @param param.date input date
 * @param param.format desired format
 * @returns a string formatted date
 */
export const formatDate = ({ date, format }: { date: DateInput; format: string }): string => {
  // todo : remove string type from format
  // blocked by g-axis-x.vue because of tickFormat
  try {
    return createDate(date).format(DATE_TIME_FORMATS[format] || format)
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(`${error}`)
  }
}

/**
 * Formats the date according to its input format
 * @param param.date input date
 * @param param.format desired format
 * @param param.errorLabel returned value when something is wrong with the input
 * @returns a string formatted date
 */
export const formatDateWithFallback = ({
  date,
  format,
  errorLabel
}: {
  date?: DateInput
  format: string
  errorLabel: string
}): string => {
  if (date === undefined || date === null) {
    return errorLabel
  }
  try {
    return formatDate({ date, format: format })
  } catch (error) {
    return errorLabel
  }
}

/**
 * Returns the start of a period
 * @param param.date input date
 * @param param.unit part of the date you want to start of ("day" is default)
 * @returns as a js Date the start of desired
 */
export const getStartOf = ({ date, unit = "day" }: StartAndEndOfParams): Date => {
  try {
    return dayjs(date).startOf(unit).toDate()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

/**
 * Returns the end of a period
 * @param param.date input date
 * @param param.unit part of the date you want to get the end ("day" is default)
 * @returns as a js Date the end of desired
 */
export const getEndOf = ({ date, unit = "day" }: StartAndEndOfParams): Date => {
  try {
    return dayjs(date).endOf(unit).toDate()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

/**
 * This method returns a js Date
 * @param dateObject input date
 * @returns a js Date
 */
export const toJsDate = (dateObject: Dayjs | Date | string | number): Date => {
  try {
    if (isDayjs(dateObject)) {
      return dateObject.toDate()
    }
    return createDate(dateObject).toDate()
  } catch (error) {
    throw new Error(ERROR_MESSAGE.DATE)
  }
}

/**
 * Adds a step of time to an actual date
 * @param param.time time value to add
 * @param param.date input date
 * @param param.step unit of time to add
 * @returns a js Date
 */
export const addTime = ({ date, time, unit = "day" }: AdditionTimeParams): Date => {
  try {
    return createDate(date).add(time, unit).toDate()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

/**
 * Removes some time to an actual date
 * @param param.time time value to remove
 * @param param.date input date
 * @param param.unit unit of time to remove
 * @returns a js Date
 */
export const removeTime = ({ date, time, unit = "day" }: AdditionTimeParams): Date => {
  try {
    return createDate(date).subtract(time, unit).toDate()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

export const formatUtcDate = ({ date, format }: { date: DateInput; format: string }): string => {
  try {
    return createDate(date)
      .utcOffset(0)
      .format(DATE_TIME_FORMATS[format] || format)
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(`${error}`)
  }
}

export const toUtc = (date: DateInput): Date => {
  try {
    return createDate(date).utcOffset(0).toDate()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

/**
 * @param date input date
 * @returns a js Date in user timezone
 */
export const toLocalUserTimezoneDate = (date: DateInput): Date => {
  try {
    return createDate(toUtc(date)).local().toDate()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

/**
 * @param date input date
 * @returns a string date in format DD/MM/YYYY h:mm:ss
 */
export const toLocalDateTimeString = (
  date: DateInput,
  locale: string | undefined = undefined
): string => {
  try {
    const jsDate = toJsDate(toUtc(createDate(date)))
    return `${jsDate.toLocaleDateString(locale)} ${jsDate.toLocaleTimeString(locale)}`
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

/**
 * @param date input date
 * @returns a string Date in user format
 */
export const toLocalUserFormatString = (date: DateInput): string => {
  try {
    return formatDate({ date, format: DATE_TIME_FORMATS.shortDateFormat })
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

/**
 * Returns the time between two dates
 * @param param.aDate reference date
 * @param param.bDate operator date
 * @param param.unit unit of the output
 * @returns the number of unit between the two inputs
 */
export const getDiff = ({
  aDate,
  bDate,
  unit
}: {
  aDate: DateInput
  bDate: DateInput
  unit?: QUnitType | OpUnitType
}): number => {
  try {
    return createDate(aDate).diff(createDate(bDate), unit)
  } catch (error) {
    console.error(ERROR_MESSAGE.DATE)
    return NaN
  }
}

/* todo : remove DurationInputArg1 and Duration
  Maybe remove the function and keep getDurationAs ?
*/
export const getDuration = (dur: any): duration.Duration => {
  try {
    return dayjs.duration(dur)
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DURATION)
  }
}

/* todo : remove DurationInputArg1 */
export const getDurationAs = ({
  duration: dur,
  unit
}: {
  duration: any
  unit: ManipulateType
}): number => {
  try {
    return dayjs.duration(dur).as(unit)
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DURATION)
  }
}

/* todo : remove DurationInputArg1
  Maybe remove the function and keep getDurationAs ?
*/
export const getDurationForHuman = ({ duration: dur }: { duration: any }): string => {
  try {
    return dayjs.duration(dur).humanize()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DURATION)
  }
}

/**
 * Returns in an approximate way the difference between now and the input date
 * @param date input date
 * @returns a description of the time between the given date and now
 */
export const fromNow = (date: DateInput): string => {
  try {
    return createDate(date).fromNow()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.UNKNOWN)
  }
}

/**
 * @param short when true, display the 3 first char of the months
 * @returns an array of Months
 */
export const getMonths = (short?: boolean): string[] => {
  return short ? dayjs.monthsShort() : dayjs.months()
}

/**
 * This method returns the index of the month of the input date
 * 0 is January 11 is December
 * @param date input date
 * @returns the index of the month
 */
export const getMonthOf = (date: DateInput): number => {
  try {
    return createDate(date).month()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

export const setMonthOf = ({ date, month }: { date: string; month: number }): Date => {
  try {
    if (month > 11 || month < 0) {
      throw new Error(ERROR_MESSAGE.MONTHS)
    }
    return toJsDate(createDate(date).month(month))
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}
/**
 * @param date input date
 * @returns date's year as a number
 */
export const getYear = (date: DateInput): number => {
  try {
    return createDate(date).year()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

/**
 * @param date a DateInput
 * @returns an ISO date string
 */
export const toIsoString = ({ date }: { date: DateInput }): string => {
  return dayjs.utc(date).toISOString()
}

export const getUtcOffset = (date: DateInput) => {
  return createDate(date).utcOffset()
}

export const isValidDate = (dateObject: DateInput): boolean => {
  if (!dateObject) return false

  const isValid = dayjs(dateObject).isValid()
  return isValid
}

export const dateFromReference = ({
  date,
  reference
}: {
  date: DateInput
  reference?: DateInput
}): string => {
  return createDate(date).calendar(reference, { sameElse: DATE_TIME_FORMATS.shortDateFormat })
}

export const formatDateAsDateString = (date: DateInput): DateString => {
  return formatDate({ date, format: DATE_TIME_FORMATS.isoOnlyDateFormat }) as DateString
}

export const getIsoStartOfUserTimezoneInUTC = ({
  date,
  unit = "day"
}: StartAndEndOfParams): String => {
  try {
    return createDate(date).startOf(unit).toISOString()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

export const getIsoEndOfUserTimezoneInUTC = ({
  date,
  unit = "day"
}: StartAndEndOfParams): String => {
  try {
    return createDate(date).endOf(unit).toISOString()
  } catch (error) {
    if (error instanceof Error) throw error
    else throw new Error(ERROR_MESSAGE.DATE)
  }
}

export const parseCurrent = (date, unit: ManipulateType = "M") => {
  if (date.match(/current/)) {
    let time = date.split(/current/).pop()
    const currentDate = toJsDate(createDate(new Date()))
    if (date.match(/-/)) {
      time = time.split("-").pop()
      return removeTime({ time, date: currentDate, unit })
    } else if (date.match(/\+/)) {
      time = time.split("+").pop()
      return addTime({ time, date: currentDate, unit })
    }
    return currentDate
  }
  return toJsDate(createDate(date))
}

export const isSame = ({
  date1,
  date2
}: {
  date1: Date | string | number
  date2: Date | string | number
}) => {
  return dayjs(date1).isSame(date2)
}

export const isBefore = ({
  dateToCompare,
  comparisonDate
}: {
  dateToCompare: Date | string | number
  comparisonDate: Date | string | number
}) => {
  return dayjs(dateToCompare).isBefore(comparisonDate)
}

export const isSameOrBefore = ({
  dateToCompare,
  comparisonDate
}: {
  dateToCompare: Date | string | number
  comparisonDate: Date | string | number
}) => {
  return dayjs(dateToCompare).isSameOrBefore(comparisonDate)
}

/**
 * @param date input date
 * @returns the count of days for the given month
 */
export const daysInMonth = (date: DateInput): number => {
  return dayjs(date).daysInMonth()
}

export const getNumericYear = (date: DateInput): number => {
  return parseInt(formatDate({ date: date, format: DATE_TIME_FORMATS.numericYear }), 10)
}

export const getNumericMonth = (date: DateInput): number => {
  return parseInt(formatDate({ date: date, format: DATE_TIME_FORMATS.monthOnlyFormat }), 10)
}

export const getNumericDay = (date: DateInput): number => {
  return parseInt(formatDate({ date: date, format: DATE_TIME_FORMATS.numericDayOfMonth }), 10)
}

export const getNumericDayOfWeek = (date: DateInput): number => {
  return createDate(date).day()
}

/** @internal
 * ISO 8601 implement rules like P1M, but js fails at handling correctly setMonth(>11)
 * this utilitary tricks this
 *
 * This well implemented in `Temporal`, but it's not released at the moment this utilitary have been written.
 */
export const addDuration = ({ date, duration }: { date: DateInput; duration: string }) =>
  addTime({
    date,
    time: getDuration(duration),
    unit: "millisecond"
  })

export const getWeekDays = () => {
  return dayjs.localeData().weekdays()
}

export default {
  setLocale,
  createDate,
  formatDate,
  getStartOf,
  getEndOf,
  toJsDate,
  addTime,
  removeTime,
  toUtc,
  toLocalUserTimezoneDate,
  toLocalUserFormatString,
  getDiff,
  getDuration,
  getDurationAs,
  fromNow,
  getMonths,
  getMonthOf,
  setMonthOf,
  getYear,
  toIsoString,
  getUtcOffset,
  isValidDate,
  dateFromReference,
  getIsoStartOfUserTimezone: getIsoStartOfUserTimezoneInUTC,
  getIsoEndOfUserTimezone: getIsoEndOfUserTimezoneInUTC,
  getDurationForHuman,
  formatUtcDate,
  daysInMonth,
  addDuration,
  parseDate,
  formatDateWithFallback,
  toLocalDateTimeString,
  getWeekDays
}
