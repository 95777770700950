<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0076 18.4075L16.0676 13.4675L13.9476 15.5875L18.8876 20.5275C19.4776 21.1175 20.4276 21.1175 21.0076 20.5275C21.5876 19.9375 21.5876 18.9875 21.0076 18.4075Z"
      fill="currentColor" />
    <path
      d="M17.6676 10.4175C19.5976 10.4175 21.1676 8.84753 21.1676 6.91753C21.1676 6.33753 21.0076 5.79753 20.7576 5.31753L18.0576 8.01753L16.5676 6.52753L19.2676 3.82753C18.7876 3.57753 18.2476 3.41753 17.6676 3.41753C15.7376 3.41753 14.1676 4.98753 14.1676 6.91753C14.1676 7.32753 14.2476 7.71753 14.3776 8.07753L12.5276 9.92753L10.7476 8.14753C11.1376 7.75753 11.1376 7.12753 10.7476 6.73753L10.0376 6.02753L12.1576 3.90753C10.9876 2.73753 9.08762 2.73753 7.91762 3.90753L5.09762 6.73753C4.70762 7.12753 4.70762 7.75753 5.09762 8.14753L5.80762 8.85753H3.26762C3.07762 8.85753 2.89762 8.92753 2.76762 9.06753C2.48762 9.34753 2.48762 9.78753 2.76762 10.0675L5.30762 12.6075C5.58762 12.8875 6.02762 12.8875 6.30762 12.6075C6.43762 12.4775 6.51762 12.2975 6.51762 12.1075V9.56753L7.21762 10.2675C7.60762 10.6575 8.23762 10.6575 8.62762 10.2675L10.4076 12.0475L4.05762 18.3975C3.46762 18.9875 3.46762 19.9375 4.05762 20.5175C4.64762 21.1075 5.59762 21.1075 6.17762 20.5175L16.4976 10.2075C16.8676 10.3375 17.2576 10.4175 17.6676 10.4175Z"
      fill="currentColor" />
  </svg>
</template>
