import { useTranslate } from "../composables/use-translate"

import type { FluidForDisplay, FluidFromConfig } from "@/src/typings/fluids"

export const AVAILABLE_FLUIDS = {
  BIOMASS: "biomass",
  DISTRICT_COOLING: "district_cooling",
  DISTRICT_HEATING: "district_heating",
  ELECTRICITY: "electricity",
  FUEL: "fuel",
  GAS: "gas",
  OTHER: "other",
  PELLETS: "pellets",
  PROPANE: "propane",
  WASTE: "waste",
  WATER: "water",
  WOOD_CHIPS: "wood_chips",
  PHOTOVOLTAIC: "photovoltaic",
  REFRIGERANT: "refrigerant"
} as const

export type FluidId = (typeof AVAILABLE_FLUIDS)[keyof typeof AVAILABLE_FLUIDS]

export const setDefaultsForFluids = (fluidConfig: FluidFromConfig[], translate) => {
  return fluidConfig.map((m) => {
    const displayNames: Record<FluidId, string> = {
      electricity: translate({ defaultMessage: "Electricity" }),
      gas: translate({ defaultMessage: "Gas" }),
      water: translate({ defaultMessage: "Water" }),
      fuel: translate({ defaultMessage: "Fuel" }),
      district_heating: translate({ defaultMessage: "District heating" }),
      district_cooling: translate({ defaultMessage: "District cooling" }),
      waste: translate({ defaultMessage: "Waste" }),
      other: translate({ defaultMessage: "Other" }),
      biomass: translate({ defaultMessage: "Biomass" }),
      pellets: translate({ defaultMessage: "Pellets" }),
      propane: translate({ defaultMessage: "Propane" }),
      wood_chips: translate({ defaultMessage: "Wood chips" }),
      photovoltaic: translate({ defaultMessage: "Photovoltaic" }),
      refrigerant: translate({ defaultMessage: "Refrigerant" })
    }
    return {
      ...m,
      display_name: m.display_name || displayNames[m.id] || m.id,
      color: m.color || fluids[m.id]?.color,
      icon: m.icon || fluids[m.id]?.icon,
      id: m.id
    }
  })
}

export const useFluidsTranslations = () => {
  const { translate } = useTranslate()

  const getFluidTranslation = (fluidId: string) => {
    switch (fluidId) {
      case "biomass":
        return translate({ defaultMessage: "Biomass" })
      case "district_cooling":
        return translate({ defaultMessage: "District cooling" })
      case "district_heating":
        return translate({ defaultMessage: "District heating" })
      case "electricity":
        return translate({ defaultMessage: "Electricity" })
      case "fuel":
        return translate({ defaultMessage: "Fuel" })
      case "gas":
        return translate({ defaultMessage: "Gas" })
      case "other":
        return translate({ defaultMessage: "Other" })
      case "pellets":
        return translate({ defaultMessage: "Pellets" })
      case "photovoltaic":
        return translate({ defaultMessage: "Photovoltaic" })
      case "propane":
        return translate({ defaultMessage: "Propane" })
      case "refrigerant":
        return translate({ defaultMessage: "Refrigerant" })
      case "waste":
        return translate({ defaultMessage: "Waste" })
      case "water":
        return translate({ defaultMessage: "Water" })
      case "wood_chips":
        return translate({ defaultMessage: "Wood chips" })
      case "coal":
        return translate({ defaultMessage: "Coal" })
      default:
        throw new Error("Undefined fluid", {
          cause: { "invalid-fluid": fluidId }
        })
    }
  }

  return { getFluidTranslation }
}

/**
 * Fluids colors & icons from Design System
 */
export const fluids: Record<FluidId, FluidForDisplay> = {
  electricity: {
    id: "electricity",
    display_name: "electricity",
    color: "var(--volta-fluid-electricity-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-electricity-color-400)",
      secondary: "var(--volta-fluid-electricity-color-200)"
    },
    icon: "fa fa-bolt"
  },
  water: {
    id: "water",
    display_name: "water",
    color: "var(--volta-fluid-water-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-water-color-400)",
      secondary: "var(--volta-fluid-water-color-200)"
    },
    icon: "fa fa-tint"
  },
  gas: {
    id: "gas",
    display_name: "gas",
    color: "var(--volta-fluid-gas-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-gas-color-400)",
      secondary: "var(--volta-fluid-gas-color-200)"
    },
    icon: "fa fa-fire"
  },
  fuel: {
    id: "fuel",
    display_name: "fuel",
    color: "var(--volta-fluid-fuel-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-fuel-color-400)",
      secondary: "var(--volta-fluid-fuel-color-200)"
    },
    icon: "dr-maki-fuel"
  },
  district_heating: {
    id: "district_heating",
    display_name: "district_heating",
    color: "var(--volta-fluid-district-heating-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-district-heating-color-400)",
      secondary: "var(--volta-fluid-district-heating-color-200)"
    },
    icon: "dr-entypo-flow-tree"
  },
  district_cooling: {
    id: "district_cooling",
    display_name: "district_cooling",
    color: "var(--volta-fluid-district-cooling-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-district-cooling-color-400)",
      secondary: "var(--volta-fluid-district-cooling-color-200)"
    },
    icon: "fa-snowflake-o"
  },
  waste: {
    id: "waste",
    display_name: "waste",
    color: "var(--volta-fluid-waste-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-waste-color-400)",
      secondary: "var(--volta-fluid-waste-color-200)"
    },
    icon: "fa fa-trash"
  },
  biomass: {
    id: "biomass",
    display_name: "Biomasse",
    color: "var(--volta-fluid-biomass-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-biomass-color-400)",
      secondary: "var(--volta-fluid-biomass-color-200)"
    },
    icon: "fa fa-leaf"
  },
  pellets: {
    id: "pellets",
    display_name: "Pellets",
    color: "var(--volta-fluid-pellets-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-pellets-color-400)",
      secondary: "var(--volta-fluid-pellets-color-200)"
    },
    icon: "fa-tree"
  },
  propane: {
    id: "propane",
    display_name: "Propane",
    color: "var(--volta-fluid-propane-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-propane-color-400)",
      secondary: "var(--volta-fluid-propane-color-200)"
    },
    icon: "fa-fire"
  },
  wood_chips: {
    id: "wood_chips",
    display_name: "Wood chips",
    color: "var(--volta-fluid-wood-chips-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-wood-chips-color-400)",
      secondary: "var(--volta-fluid-wood-chips-color-200)"
    },
    icon: "fa fa-tree"
  },
  photovoltaic: {
    id: "photovoltaic",
    display_name: "Photovoltaic",
    color: "var(--volta-fluid-photovoltaic-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-photovoltaic-color-400)",
      secondary: "var(--volta-fluid-photovoltaic-color-200)"
    },
    icon: "fa fa-solar-panel"
  },
  refrigerant: {
    id: "refrigerant",
    display_name: "Refrigerant",
    color: "var(--volta-fluid-other-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-other-color-400)",
      secondary: "var(--volta-fluid-other-color-200)"
    },
    icon: "fa fa-thermometer-half"
  },
  // Other is not a real fluid
  // It is used for fluid aggregation
  other: {
    id: "other",
    display_name: "other",
    color: "var(--volta-fluid-other-color-600)",
    dataviz: {
      primary: "var(--volta-fluid-other-color-400)",
      secondary: "var(--volta-fluid-other-color-200)"
    },
    icon: ""
  }
}

export type UnknownFluids = Record<string, FluidForDisplay>
