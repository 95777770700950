const ICONS_V4 = {
  "address-book-o": "address-book",
  "address-card-o": "address-card",
  "area-chart": "chart-area",
  "arrow-circle-o-down": "arrow-alt-circle-down",
  "arrow-circle-o-left": "arrow-alt-circle-left",
  "arrow-circle-o-right": "arrow-alt-circle-right",
  "arrow-circle-o-up": "arrow-alt-circle-up",
  "arrows-alt": "expand-arrows-alt",
  "arrows-h": "arrows-alt-h",
  "arrows-v": "arrows-alt-v",
  arrows: "arrows-alt",
  "asl-interpreting": "american-sign-language-interpreting",
  automobile: "car",
  bank: "university",
  "bar-chart-o": "chart-bar",
  "bar-chart": "chart-bar",
  bathtub: "bath",
  "battery-0": "battery-empty",
  "battery-1": "battery-quarter",
  "battery-2": "battery-half",
  "battery-3": "battery-three-quarters",
  "battery-4": "battery-full",
  battery: "battery-full",
  "bell-o": "bell",
  "bell-slash-o": "bell-slash",
  "bitbucket-square": "bitbucket",
  bitcoin: "btc",
  "bookmark-o": "bookmark",
  "building-o": "building",
  cab: "taxi",
  "calendar-check-o": "calendar-check",
  "calendar-minus-o": "calendar-minus",
  "calendar-o": "calendar",
  "calendar-plus-o": "calendar-plus",
  "calendar-times-o": "calendar-times",
  calendar: "calendar-alt",
  "caret-square-o-down": "caret-square-down",
  "caret-square-o-left": "caret-square-left",
  "caret-square-o-right": "caret-square-right",
  "caret-square-o-up": "caret-square-up",
  cc: "closed-captioning",
  "chain-broken": "unlink",
  chain: "link",
  "check-circle-o": "check-circle",
  "check-square-o": "check-square",
  "circle-o-notch": "circle-notch",
  "circle-o": "circle",
  "circle-thin": "circle",
  "clock-o": "clock",
  close: "times",
  "cloud-download": "cloud-download-alt",
  "cloud-upload": "cloud-upload-alt",
  cny: "yen-sign",
  "code-fork": "code-branch",
  "comment-o": "comment",
  "commenting-o": "comment-alt",
  commenting: "comment-alt",
  "comments-o": "comments",
  "credit-card-alt": "credit-card",
  cutlery: "utensils",
  dashboard: "tachometer-alt",
  deafness: "deaf",
  dedent: "outdent",
  diamond: "gem",
  dollar: "dollar-sign",
  "dot-circle-o": "dot-circle",
  "drivers-license-o": "id-card",
  "drivers-license": "id-card",
  eercast: "sellcast",
  "envelope-o": "envelope",
  "envelope-open-o": "envelope-open",
  eur: "euro-sign",
  euro: "euro-sign",
  exchange: "exchange-alt",
  "external-link-square": "external-link-square-alt",
  "external-link": "external-link-alt",
  eyedropper: "eye-dropper",
  fa: "flag",
  "facebook-f": "facebook-f",
  "facebook-official": "facebook",
  facebook: "facebook-f",
  feed: "rss",
  "file-archive-o": "file-archive",
  "file-audio-o": "file-audio",
  "file-code-o": "file-code",
  "file-excel-o": "file-excel",
  "file-image-o": "file-image",
  "file-movie-o": "file-video",
  "file-o": "file",
  "file-pdf-o": "file-pdf",
  "file-photo-o": "file-image",
  "file-picture-o": "file-image",
  "file-powerpoint-o": "file-powerpoint",
  "file-sound-o": "file-audio",
  "file-text-o": "file-alt",
  "file-text": "file-alt",
  "file-video-o": "file-video",
  "file-word-o": "file-word",
  "file-zip-o": "file-archive",
  "files-o": "copy",
  "flag-o": "flag",
  flash: "bolt",
  "floppy-o": "save",
  "folder-o": "folder",
  "folder-open-o": "folder-open",
  "frown-o": "frown",
  "futbol-o": "futbol",
  gbp: "pound-sign",
  ge: "empire",
  gear: "cog",
  gears: "cogs",
  gittip: "gratipay",
  glass: "glass-martini",
  "google-plus-circle": "google-plus",
  "google-plus-official": "google-plus",
  "google-plus": "google-plus-g",
  group: "users",
  "hand-grab-o": "hand-rock",
  "hand-lizard-o": "hand-lizard",
  "hand-o-down": "hand-point-down",
  "hand-o-left": "hand-point-left",
  "hand-o-right": "hand-point-right",
  "hand-o-up": "hand-point-up",
  "hand-paper-o": "hand-paper",
  "hand-peace-o": "hand-peace",
  "hand-pointer-o": "hand-pointer",
  "hand-rock-o": "hand-rock",
  "hand-scissors-o": "hand-scissors",
  "hand-spock-o": "hand-spock",
  "hand-stop-o": "hand-paper",
  "handshake-o": "handshake",
  "hard-of-hearing": "deaf",
  "hdd-o": "hdd",
  header: "heading",
  "heart-o": "heart",
  "hospital-o": "hospital",
  hotel: "bed",
  "hourglass-1": "hourglass-start",
  "hourglass-2": "hourglass-half",
  "hourglass-3": "hourglass-end",
  "hourglass-o": "hourglass",
  "id-card-o": "id-card",
  ils: "shekel-sign",
  image: "image",
  inr: "rupee-sign",
  institution: "university",
  intersex: "transgender",
  jpy: "yen-sign",
  "keyboard-o": "keyboard",
  krw: "won-sign",
  legal: "gavel",
  "lemon-o": "lemon",
  "level-down": "level-down-alt",
  "level-up": "level-up-alt",
  "life-bouy": "life-ring",
  "life-buoy": "life-ring",
  "life-saver": "life-ring",
  "lightbulb-o": "lightbulb",
  "line-chart": "chart-line",
  "linkedin-square": "linkedin",
  linkedin: "linkedin-in",
  "long-arrow-down": "long-arrow-alt-down",
  "long-arrow-left": "long-arrow-alt-left",
  "long-arrow-right": "long-arrow-alt-right",
  "long-arrow-up": "long-arrow-alt-up",
  "mail-forward": "share",
  "mail-reply-all": "reply-all",
  "mail-reply": "reply",
  "map-marker": "map-marker-alt",
  "map-o": "map",
  meanpath: "font-awesome",
  "meh-o": "meh",
  "minus-square-o": "minus-square",
  "mobile-phone": "mobile-alt",
  mobile: "mobile-alt",
  money: "money-bill-alt",
  "moon-o": "moon",
  "mortar-board": "graduation-cap",
  navicon: "bars",
  "newspaper-o": "newspaper",
  "paper-plane-o": "paper-plane",
  paste: "clipboard",
  "pause-circle-o": "pause-circle",
  "pencil-square-o": "edit",
  "pencil-square": "pen-square",
  pencil: "fa-pencil-alt",
  photo: "image",
  "picture-o": "image",
  "pie-chart": "chart-pie",
  "play-circle-o": "play-circle",
  "plus-square-o": "plus-square",
  "question-circle-o": "question-circle",
  ra: "rebel",
  refresh: "sync",
  remove: "times",
  reorder: "bars",
  repeat: "redo",
  resistance: "rebel",
  rmb: "yen-sign",
  "rotate-left": "undo",
  "rotate-right": "redo",
  rouble: "ruble-sign",
  rub: "ruble-sign",
  ruble: "ruble-sign",
  rupee: "rupee-sign",
  s15: "bath",
  scissors: "cut",
  "send-o": "paper-plane",
  send: "paper-plane",
  "share-square-o": "share-square",
  shekel: "shekel-sign",
  sheqel: "shekel-sign",
  "sign-in": "sign-in-alt",
  "sign-out": "sign-out-alt",
  signing: "sign-language",
  sliders: "sliders-h",
  "smile-o": "smile",
  "snowflake-o": "snowflake",
  "soccer-ball-o": "futbol",
  "sort-alpha-asc": "sort-alpha-down",
  "sort-alpha-desc": "sort-alpha-up",
  "sort-amount-asc": "sort-amount-down",
  "sort-amount-desc": "sort-amount-up",
  "sort-asc": "sort-up",
  "sort-desc": "sort-down",
  "sort-numeric-asc": "sort-numeric-down",
  "sort-numeric-desc": "sort-numeric-up",
  spoon: "utensil-spoon",
  "square-o": "square",
  "star-half-empty": "star-half",
  "star-half-full": "star-half",
  "star-half-o": "star-half",
  "star-o": "star",
  "sticky-note-o": "sticky-note",
  "stop-circle-o": "stop-circle",
  "sun-o": "sun",
  support: "life-ring",
  tablet: "tablet-alt",
  tachometer: "tachometer-alt",
  television: "tv",
  "thermometer-0": "thermometer-empty",
  "thermometer-1": "thermometer-quarter",
  "thermometer-2": "thermometer-half",
  "thermometer-3": "thermometer-three-quarters",
  "thermometer-4": "thermometer-full",
  thermometer: "thermometer-full",
  "thumb-tack": "thumbtack",
  "thumbs-o-down": "thumbs-down",
  "thumbs-o-up": "thumbs-up",
  ticket: "ticket-alt",
  "times-circle-o": "times-circle",
  "times-rectangle-o": "window-close",
  "times-rectangle": "window-close",
  "toggle-down": "caret-square-down",
  "toggle-left": "caret-square-left",
  "toggle-right": "caret-square-right",
  "toggle-up": "caret-square-up",
  "trash-o": "trash-alt",
  try: "lira-sign",
  "turkish-lira": "lira-sign",
  unsorted: "sort",
  usd: "dollar-sign",
  "user-circle-o": "user-circle",
  "user-o": "user",
  "vcard-o": "address-card",
  vcard: "address-card",
  "video-camera": "video",
  vimeo: "vimeo-v",
  "volume-control-phone": "phone-volume",
  warning: "exclamation-triangle",
  wechat: "weixin",
  "wheelchair-alt": "accessible-icon",
  "window-close-o": "window-close",
  won: "won-sign",
  "y-combinator-square": "hacker-news",
  "yc-square": "hacker-news",
  yc: "y-combinator",
  yen: "yen-sign",
  "youtube-play": "youtube",
  "youtube-square": "youtube"
}

/**
 * Converts font awesome v4 icon name to v5
 */
export function fa4to5(v4Icon: string): string {
  const iconName = v4Icon.slice(3)

  return ICONS_V4[iconName] ? `fa-${ICONS_V4[iconName]}` : v4Icon
}
