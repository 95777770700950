<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0044 13.5186L9.97439 15.5186C9.51439 15.9786 9.15439 16.5486 9.04439 17.1886C8.74439 19.0286 10.1844 20.6186 12.0044 20.6186C13.8244 20.6186 15.2644 19.0286 14.9644 17.1986C14.8544 16.5586 14.5044 15.9786 14.0344 15.5286L12.0044 13.5186Z"
      fill="currentColor" />
    <path
      d="M15.5644 7.16858C14.3844 8.63858 12.0044 7.80858 12.0044 5.91858V4.38858C12.0044 3.58858 11.1144 3.10858 10.4544 3.54858C8.12439 5.10858 4.00439 8.58858 4.00439 13.6186C4.00439 16.5386 5.56439 19.0886 7.89439 20.4786C7.18439 19.4586 6.83439 18.1686 7.08439 16.7986C7.27439 15.7586 7.83439 14.8186 8.59439 14.0786L11.3044 11.4086C11.6944 11.0286 12.3144 11.0286 12.7044 11.4086L15.4344 14.0986C16.1744 14.8286 16.7344 15.7486 16.9144 16.7786C17.1644 18.1386 16.8444 19.4186 16.1444 20.4386C18.0344 19.2886 19.4344 17.3786 19.8544 15.1386C20.4644 11.8686 19.0444 8.76858 16.6344 7.03858C16.3044 6.78858 15.8344 6.83858 15.5644 7.16858Z"
      fill="currentColor" />
  </svg>
</template>
