import pointer from "json-pointer"
import { type DeepReadonly, readonly, type Ref, ref } from "vue"

import { useTranslate } from "../composables/use-translate"

import { convertFromBackend } from "./objects"

/**
 * Expects an `AxiosError` object
 *
 * @param err
 * @param reporter Function to handle message
 */
export default function (err: any, reporter: (message: string) => void = () => {}): void {
  const { translate } = useTranslate()

  if (!err) {
    // Nothing to do
    return
  }

  //json error
  if (err?.data?.error?.message) {
    reporter(err.data.error.message)
    return
  }

  const text = err.response ? err.response.data : err.responseText ? err.responseText : err.data
  const status = err.response ? err.response.status : err.status

  if (!err || !status) {
    reporter(translate({ defaultMessage: "An error has occurred. Please retry later." }))
  } else if (status === 403) {
    reporter(translate({ defaultMessage: "You cannot access this data" }))
  } else if (status === 400) {
    if (text instanceof Object && text.error) {
      reporter(text.error.message)
    } else if (text && text !== "BAD REQUEST" && text.length < 100) {
      reporter(text)
    } else {
      reporter(translate({ defaultMessage: "Error: the service is temporary unavailable" }))
    }
  } else if (status === 404) {
    if (text && text.toLowerCase() !== "not found" && text.length < 100) {
      reporter(text)
    } else {
      reporter(translate({ defaultMessage: "We have not found this data" }))
    }
  } else if ([500, 503, 504, 408, 413].indexOf(status) !== -1) {
    reporter(translate({ defaultMessage: "Error: the service is temporary unavailable" }))
  } else {
    reporter(translate({ defaultMessage: "An error has occurred. Please retry later." }))
  }
}

type ErrorResponse = {
  data: {
    error: {
      code: string //Code can differ between an openapi and python validation
      message?: string //Only if python validated
      data: {
        fields: Record<string, string[]>
        general?: string[] //Exists but empty in python validation but does not exist if empty in openapi validation
      }
    }
  }
}

type FormErrorsResult = {
  formErrors: DeepReadonly<Ref<any>>
  generalErrors: DeepReadonly<Ref<string[]>>
  parseFormErrors: (response: ErrorResponse) => void
  parseGeneralErrors: (response: ErrorResponse) => void
  parseErrors: (response: ErrorResponse) => void
}

export const useFormErrors = (): FormErrorsResult => {
  const formErrors = ref<any>({})
  const generalErrors = ref<string[]>([])

  const parseFormErrors = (response: ErrorResponse): void => {
    const errors = {}

    Object.entries(response.data.error.data.fields).forEach(([key, value]) => {
      pointer.set(errors, key, value)
    })

    formErrors.value = convertFromBackend(errors)
  }

  const parseGeneralErrors = (response: ErrorResponse): void => {
    generalErrors.value = response.data.error.data?.general ?? []
  }

  const parseErrors = (response: ErrorResponse): void => {
    parseFormErrors(response)
    parseGeneralErrors(response)
  }

  return {
    formErrors: readonly(formErrors),
    generalErrors: readonly(generalErrors),
    parseFormErrors,
    parseGeneralErrors,
    parseErrors
  }
}

export class UnreachableError extends Error {
  constructor(_nvr: never, message: string) {
    super(message)
  }
}
