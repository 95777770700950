import { CONTROL_TYPES } from "./consts"

import type { NormalizedScopeQueryParams, ScopeControllerField, ScopeQueryParams } from "./types"
import type { LocationQuery } from "vue-router"

export const MODE = ":mode"
export const SCOPE = "scope."

export const getCurrentUrl = () => {
  return new URL(document.location.toString())
}
/**
 * Read url and format result for API
 */
export const getScopeFromQueryParams = () => {
  const { searchParams } = getCurrentUrl()
  if (!searchParams) return null

  return JSON.stringify(formatScopeFromQueryParams(Object.fromEntries(searchParams)))
}

const formatScopeFromQueryParams = (searchParams: Record<string, string>) => {
  const formattedSearchParams = Object.keys(searchParams)
    .filter((key) => key.startsWith(SCOPE))
    .reduce((acc, curr) => {
      const value = searchParams[curr]

      if (curr.endsWith(MODE)) {
        acc[curr.replace(SCOPE, "")] = value
      } else if (value === "true") {
        acc[curr.replace(SCOPE, "")] = [true]
      } else {
        acc[curr.replace(SCOPE, "")] = value.split("|").filter(Boolean)
      }

      return acc
    }, {})

  if (Object.keys(formattedSearchParams).length) {
    return formattedSearchParams
  }

  return undefined
}

/**
 * Helper to retrieve only scope controller related query params from a vue-router query
 */
export const getScopeQueryParams = (query: LocationQuery): Record<string, any> => {
  if (!query) return {}

  return Object.keys(query)
    .filter((key) => key.startsWith(SCOPE))
    .reduce((acc, curr) => {
      if (query[curr]) acc[curr] = query[curr]

      return acc
    }, {})
}

/**
 * Helper to retrieve all query params not related with scope controller
 */
export const queryParamsContext = (query: LocationQuery): Record<string, any> => {
  if (!query) return {}

  return Object.keys(query)
    .filter((key) => !key.startsWith(SCOPE))
    .reduce((acc, curr) => {
      acc[curr] = query[curr]

      return acc
    }, {})
}

/**
 * This helper transform the normalized frontend format into the url format
 */
export const formatQueryParamsForUrl = (
  queryParams: NormalizedScopeQueryParams
): ScopeQueryParams => {
  return Object.keys(queryParams).reduce((acc, curr) => {
    if (queryParams[curr]?.values?.length) {
      acc[`${SCOPE}${curr}`] = queryParams[curr]?.values?.map(({ value }) => value).join("|")
    }

    if (queryParams[curr]?.excluded) {
      acc[`${SCOPE}${curr}${MODE}`] = "excluded"
    }
    return acc
  }, {})
}

/**
 * This helper transform the url format into the normalized frontend format
 */
export const formatQueryParamsForApi = (
  queryParams: NormalizedScopeQueryParams
): ScopeQueryParams => {
  return Object.keys(queryParams).reduce((acc, curr) => {
    if (queryParams[curr]?.values) {
      acc[`${curr}`] = queryParams[curr]?.values.map(({ value }) => value)
    }

    if (queryParams[curr]?.excluded) {
      acc[`${curr}${MODE}`] = "excluded"
    }
    return acc
  }, {})
}

export const getNormalizedScope = (
  scopeQueryParams: ScopeQueryParams,
  scopeFieldsConfig: ScopeControllerField[] | null
): NormalizedScopeQueryParams =>
  Object.keys(scopeQueryParams).reduce((acc, curr) => {
    if (curr.endsWith(MODE)) {
      const currentField = curr.slice(SCOPE.length, -MODE.length)

      acc[currentField] = {
        ...acc[currentField],
        excluded: scopeQueryParams[curr] === "excluded"
      }
      return acc
    }

    const currentField = curr.slice(SCOPE.length)

    // No `currentField` means your value is not a scope one and shouldn't have been passed here
    if (!currentField) return acc

    const fieldOptions = scopeFieldsConfig?.find(({ id }) => id === currentField)

    if (!fieldOptions) return acc

    acc[currentField] = {
      ...acc[currentField],
      values: scopeQueryParams[curr]
        .split("|")
        .filter(Boolean)
        .map((item) => setValues(fieldOptions, item))
    }
    return acc
  }, {})

const setValues = (fieldOptions: ScopeControllerField, item: string) => {
  if (!fieldOptions) return null

  if (fieldOptions.type === CONTROL_TYPES.CHECKBOX) {
    if (item === "true") return { value: true }
    return null
  } else if (fieldOptions.type === CONTROL_TYPES.SELECT) {
    return (
      fieldOptions.options?.find((fieldOption) => fieldOption.value === item) || {
        label: item,
        value: item
      }
    )
  }

  return null
}
