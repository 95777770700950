import { rgb as d3ColorRGB } from "d3-color"
import flattenDeep from "lodash/flattenDeep"

import colorPalettes from "@/src/utils/color-palettes"

import arrayUtils from "./array-utils"

const utilsObject = {}

// JQUERY helper to transform $.params(object) to vanilla JS
utilsObject.serializeObject = function (object) {
  return Object.keys(object)
    .map((key) => `${key}=${object[key] === null ? "" : encodeURIComponent(object[key])}`)
    .join("&")
}

// helper to change bad formated config urls
utilsObject.replaceHashInUrl = function (url) {
  if (url?.match(/\/#\//)) {
    // catch /#/
    return url?.replace(/\/#/, "")
  }
  if (url?.match(/#\//)) {
    // catch only #/
    return url?.replace(/#/, "")
  }
  return url?.replace(/#/, "/") || url
}

utilsObject.colorFactory = function (palette, listLength) {
  if (palette[0] === "-") {
    // start with - :  reverse array (useful for quantitative scales)
    return utilsObject.colorFactory(palette.slice(1), listLength).concat([]).reverse()
  }
  if (!listLength && palette.indexOf(":") !== -1) {
    palette = palette.split(":")
    listLength = parseInt(palette[0])
    palette = palette[1]
  }

  if (palette.indexOf(",") !== -1) {
    return colorPalettes.interpolate(palette.split(","), listLength || 12)
  } else if (listLength === 1 && palette && !colorPalettes[palette]) {
    return colorPalettes.interpolate([palette], listLength)
  }

  if (colorPalettes[palette]) {
    if (listLength <= colorPalettes[palette].max) {
      let n = listLength
      while (!(n in colorPalettes[palette]) && n < 1000) {
        n++
      }
      return colorPalettes[palette][n].concat([]).slice(0, listLength)
    }
    return colorPalettes[palette][colorPalettes[palette].max]
  } else if (colorPalettes.gen_palette[palette]) {
    return colorPalettes.gen_palette[palette](listLength)
  }
  return utilsObject.colorFactory("default", Math.min(listLength, 12))
}

utilsObject.removeHiddenFields = function (object) {
  Object.keys(object).forEach((key) => (key.startsWith("_") ? delete object[key] : null))
}

utilsObject.deepCopy = function (o) {
  return JSON.parse(JSON.stringify(o))
}

utilsObject.yearOptions = function (yearStr) {
  const years = yearStr.split("-")
  if (years.length === 1) {
    return [{ id: years[0] }]
  }
  if (years[1] === "current") {
    years[1] = new Date().getFullYear()
  }
  years[1] = parseInt(years[1]) + 1

  return arrayUtils
    .range(parseInt(years[0]), parseInt(years[1]))
    ?.reverse()
    ?.map((year) => ({ id: year }))
}

utilsObject.availableYearsArray = function (yearStr) {
  const years = yearStr.split("-")
  if (years.length === 1) {
    if (years[0] === "current") {
      years[0] = new Date().getFullYear()
    }
    return [parseInt(years[0])]
  }
  if (years[1] === "current") {
    years[1] = new Date().getFullYear()
  }
  years[1] = parseInt(years[1]) + 1

  return arrayUtils.range(parseInt(years[0]), parseInt(years[1]))?.reverse()
}

utilsObject.pollUrl = function (httpClient, url, maxIterations, delay = 600) {
  if (maxIterations === "undefined") {
    maxIterations = 25
  }
  if (maxIterations < 0) {
    return Promise.reject({ status: 400, data: "maxIterations" })
  }
  return httpClient.get(url).then((result) => {
    if (["idle", "finished", "failed", "success", "error"].indexOf(result.data.status) > -1) {
      return result.data
    }
    return utilsObject
      .delay(delay)
      .then(() => utilsObject.pollUrl(httpClient, url, maxIterations - 1, delay))
  })
}

utilsObject.sortAscendingUndefined = (a, b) =>
  (a === undefined) - (b === undefined) || arrayUtils.ascending(a, b)
utilsObject.sortDescendingUndefined = (a, b) =>
  (a === undefined) - (b === undefined) || arrayUtils.descending(a, b)

function resetNestedIndex(nested, keynames) {
  if (keynames.length == 1) {
    return nested.map((d) => {
      d = Object.assign(d.values, d, { values: null })
      d[keynames[0]] = d.key
      delete d.key
      delete d.values
      return d
    })
  }
  const unnested = flattenDeep(
    nested.map((gb) =>
      gb.values.map((d) => {
        d[keynames[0]] = gb.key
        return d
      })
    )
  )
  // recursive on keynames top to bottom
  return resetNestedIndex(unnested, keynames.slice(1))
}

utilsObject.resetNestedIndex = resetNestedIndex

function relativeLuminance(color) {
  // https://www.w3.org/TR/WCAG20/?source=post_page---------------------------#relativeluminancedef
  const rgb = d3ColorRGB(color)
  const relativeComponent = (c) =>
    c / 255 <= 0.03928 ? c / (255 * 12.92) : Math.pow((c / 255 + 0.055) / 1.055, 2.4)
  const r = relativeComponent(rgb.r)
  const g = relativeComponent(rgb.g)
  const b = relativeComponent(rgb.b)
  return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

utilsObject.contrastRatio = function (color1, color2) {
  const l1 = relativeLuminance(color1)
  const l2 = relativeLuminance(color2)
  return l1 < l2 ? (l2 + 0.05) / (l1 + 0.05) : (l1 + 0.05) / (l2 + 0.05)
}

utilsObject.contrastWhiteOrBlack = function (color) {
  return utilsObject.contrastRatio(color, "#000") > utilsObject.contrastRatio(color, "#FFF")
    ? "#000"
    : "#FFF"
}

utilsObject.doubleEncodeUrl = function (urlElement) {
  return encodeURIComponent(encodeURIComponent(urlElement))
}

// Util to suppress reserved keys
// Fields starting with _ are not supposed to be sent to backend
utilsObject.purgeObject = (object) => {
  const keysToDelete = Object.keys(object).filter((f) => f.startsWith("_"))
  keysToDelete.forEach((key) => delete object[key])
  return object
}

utilsObject.encodeUtf8 = function (value) {
  return btoa(unescape(encodeURIComponent(JSON.stringify(value))))
}

utilsObject.decodeUtf8 = function (rawValue) {
  return JSON.parse(decodeURIComponent(escape(atob(rawValue))))
}

utilsObject.delay = function (duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, duration)
  })
}

export default utilsObject
