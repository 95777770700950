import { defineStore } from "pinia"
import { ref } from "vue"

import { useTranslate } from "@/src/composables/use-translate"
import { getIncludedJsonData, setTheme } from "@/src/core/init"
import { $http } from "@/src/plugins/vue_axios"
import { setDefaultsForFluids } from "@/src/utils/fluids"

import { STORE_KEY_PROJECT_CONFIG } from "./consts"
import { setMenu } from "./menu"

import type { ProjectConfig } from "@/src/typings/globals"

export const useProjectConfigStore = defineStore(STORE_KEY_PROJECT_CONFIG, () => {
  const { translate } = useTranslate()
  const projectConfig = ref<ProjectConfig>({} as ProjectConfig)
  const projectId = ref<string>("")

  const fetchProjectConfig = async () => {
    const includedJsonData = getIncludedJsonData()
    projectId.value = includedJsonData.project || ""

    if (projectId.value) {
      try {
        const { data } = await $http.get(`/front/${projectId.value}/config`)

        globalThis.project_config = await upgradeConfig(data, translate)
        projectConfig.value = globalThis.project_config

        if (globalThis.project_config?.app_design === "old") {
          await setTheme(globalThis.project_config?.theme || "default-green")
        } else {
          await setTheme("default")
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  return {
    projectConfig,
    fetchProjectConfig,
    projectId
  }
})

const upgradeConfig = async (projectConfig: ProjectConfig, translate): Promise<ProjectConfig> => {
  const config = Object.assign({}, projectConfig)

  const menu = setMenu(config, translate)

  if (!config.collect.fluids) return config

  return {
    ...config,
    menu,
    collect: {
      ...config.collect,
      fluids: setDefaultsForFluids(config.collect.fluids, translate)
    },
    emailSupport: emailSupport(projectConfig)
  }
}

const emailSupport = (projectConfig?: ProjectConfig) => {
  if (projectConfig?.id) {
    return projectConfig.support_email || `support+${projectConfig.id}@deepki.com`
  }
  return "support@deepki.com"
}
