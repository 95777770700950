<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2946 5.71498C16.9046 5.32498 16.2746 5.32498 15.8846 5.71498L12.0046 9.58498L8.11461 5.70498C7.72461 5.31498 7.09461 5.31498 6.70461 5.70498C6.31461 6.09498 6.31461 6.72498 6.70461 7.11498L11.2946 11.705C11.6846 12.095 12.3146 12.095 12.7046 11.705L17.2946 7.11498C17.6846 6.73498 17.6846 6.10498 17.2946 5.71498Z"
      fill="currentColor" />
    <path
      d="M17.2946 12.305C16.9046 11.915 16.2746 11.915 15.8846 12.305L12.0046 16.175L8.12461 12.295C7.73461 11.905 7.10461 11.905 6.71461 12.295C6.32461 12.685 6.32461 13.315 6.71461 13.705L11.3046 18.295C11.6946 18.685 12.3246 18.685 12.7146 18.295L17.3046 13.705C17.6846 13.325 17.6846 12.695 17.2946 12.305Z"
      fill="currentColor" />
  </svg>
</template>
